<script setup>
import Tooltip from './Tooltip.vue'
import ResponsiveSlot from './ResponsiveSlot.vue'

const breakpoints = ref([
  { name: 'mobile', range: { max: 1250 } },
  { name: 'desktop', range: { min: 1251 } }
])

const defaultOrder = ['presentation', 'firstInterview', 'followupInterview', 'contractNegotiations', 'placement']

const props = defineProps({
  /**
   * Holds either an `Array<{ name: String, timestamp: String }> or a single `String` with the current step.
   */
  display: {
    required: true,
    type: [Array, String]
  },

  steps: {
    type: Array,
    default: () => ['presentation', 'firstInterview', 'followupInterview', 'contractNegotiations', 'placement']
  },

  i18nPathPrefix: {
    type: String,
    default: 'candidate.status'
  },

  windowBased: {
    type: Boolean,
    default: false
  }
})

onBeforeMount(() => {
  if (typeof props.display === 'string') {
    if (!props.steps.includes(props.display)) {
      throw new Error(`Invalid step name "${props.display}". Valid steps are: ${props.steps.join(', ')}`)
    }
  }
})

/**
 * Generates a string for the timestamp passed to it.
 *
 * @param {String} timestamp
 * @returns {String}
 */
function generateTimestampString(index) {
  if (!isArray.value) return ''
  if (index == -1) return undefined

  const timestamp = props.display[index]?.timestamp

  if (!timestamp) {
    throw new Error(`Timestamp is not defined on ${props.display[index]}`)
  }

  return new Date(timestamp).toLocaleString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  })
}

/**
 * Will return the i18n id for the step at the given index.
 *
 * @param {number} index The index of the step
 */
function getI18nId(index) {
  if (index == -1) return 'candidate.status.unknown'
  return `${props.i18nPathPrefix}.${props.steps[index]}`
}

/**
 * Denotes wether the StatusBar is displaying a bar with timestamps or a general bar without timestamps and just the
 * different step names.
 */
const isArray = computed(() => {
  return Array.isArray(props.display)
})

/**
 * Returns the index of the current step
 */
const currentIndex = computed(() => {
  return isArray.value
    ? props.steps.indexOf(
        props.display.sort((a, b) => defaultOrder.indexOf(a.name) - defaultOrder.indexOf(b.name)).slice(-1)[0].name
      )
    : props.steps.indexOf(props.display)
})

/**
 * Returns a number of the steps that are finished.
 */
const preStepActions = computed(() => {
  return isArray.value ? props.display.length - 1 : props.steps.indexOf(props.display)
})

/**
 * ‚‚ Returns a number of the steps that are not finished.
 */
const postStepActions = computed(() => {
  return isArray.value
    ? props.steps.length - props.display.length
    : props.steps.length - props.steps.indexOf(props.display) - 1
})

/**
 * Return the total number of steps.
 */
const totalSteps = computed(() => {
  return props.steps.length
})
</script>

<template>
  <ResponsiveSlot :breakpoints="breakpoints" :window-based="windowBased">
    <template #mobile>
      <div class="path">
        <div v-if="preStepActions != 0" class="path-element pre">
          <div v-for="n in preStepActions" :key="n">
            <Tooltip
              position="right"
              :text="$t(getI18nId(n - 1)) + (isArray ? ' - ' + generateTimestampString(n - 1) : '')"
            >
              <div class="dot" />
            </Tooltip>
          </div>
        </div>
        <div class="path-element current">
          <div class="vstack">
            <p class="step-name">
              {{ $t(getI18nId(currentIndex)) }}
            </p>
            <p v-if="isArray" class="date">
              {{ generateTimestampString(props.display.findIndex(x => x.name === props.steps[currentIndex])) }}
            </p>
          </div>
        </div>
        <div v-if="postStepActions != 0" class="path-element post">
          <div v-for="n in postStepActions" :key="n">
            <Tooltip position="left" :text="$t(getI18nId(totalSteps - (postStepActions - n) - 1))">
              <div class="dot" />
            </Tooltip>
          </div>
        </div>
      </div>
    </template>
    <template #desktop>
      <div class="path desktop">
        <div
          v-for="n in totalSteps"
          :key="n"
          class="path-element desktop"
          :class="{
            pre: n - 1 < currentIndex,
            current: n - 1 == currentIndex,
            post: n - 1 > currentIndex
          }"
          :style="{ zIndex: totalSteps - n }"
        >
          <div class="vstack">
            <p class="step-name desktop">
              {{ $t(getI18nId(n - 1)) }}
            </p>
            <p v-if="isArray" class="date">
              {{ generateTimestampString(props.display.findIndex(x => x.name === props.steps[n - 1])) }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </ResponsiveSlot>
</template>

<style lang="scss" scoped>
@import '@/theme/globals.scss';

$heightMobile: 28px;
$heightDesktop: 34px;

.vstack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 0;
  z-index: 0;
}

.path {
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 0 1px #ededf0;
  background-color: #fff;
  cursor: default;
  margin-bottom: 1px; // Prevents the shadow from being cut off

  height: $heightMobile;
  border-radius: calc($heightMobile / 2);

  &.desktop {
    justify-content: space-between;

    height: $heightDesktop;
    border-radius: calc($heightDesktop / 2);
  }
}

.path-element {
  display: flex;
  align-items: center;
  justify-content: center;

  height: $heightMobile;
  padding: 0 calc($heightMobile / 3);
  border-radius: calc($heightMobile / 2);

  &:first-child {
    /* Affects .current */
    margin-left: 0;
  }

  &.desktop {
    width: 100%;
    text-align: center;

    height: $heightDesktop;
    padding: 0 calc($heightDesktop / 3 + 18px);
    border-radius: calc($heightDesktop / 2);

    box-shadow: 0 0 0 2px #ededf0;

    // border: 1px solid blueviolet;

    &:not(:first-of-type) {
      margin-left: calc(-1 * $heightDesktop);
      padding-left: calc($heightDesktop + 20px);

      // border: 1px solid salmon;
    }
  }
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 2px;
  transition: all ease-in-out 300ms;

  &:hover {
    background-color: var(--status-current-background-color);
  }
}

.pre {
  background-color: var(--status-past-background-color);
  z-index: 3;

  & p {
    color: var(--status-past-text-color);
  }
}

.current {
  font-size: small;
  color: var(--status-current-text-color);
  z-index: 2;
  flex: 1;
  background-color: var(--status-current-background-color);
  flex-shrink: 2;

  margin-left: calc(-1 * $heightMobile);

  &.desktop {
    flex: auto;
  }
}

.post {
  z-index: 2;
  background-color: #fff;

  & p {
    color: var(--font-secondary);
  }

  & .dot:not(:hover) {
    background-color: #ccc;
  }
}

p {
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  white-space: nowrap;
  text-overflow: clip;
  text-align: center;

  line-height: calc(($heightMobile - 4px) / 2);

  &.desktop {
    line-height: calc(($heightDesktop - 4px) / 3);
  }
}

.step-name {
  font-weight: medium;

  &.desktop {
    font-weight: 600;
    font-size: 14px;
  }
}

.date {
  margin-top: 2px;
  font-size: x-small;
}
</style>
