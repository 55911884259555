import cleanArray from './cleanArray'

/**
 * An interface for working with the parsed permissions.
 */
export interface JWTPermissions {
  portal: {
    wildcard: boolean
    projectIds: string[]
  }
  bi: {
    enabled: boolean
  }
  admin: {
    enabled: boolean
  }
}

/**
 * This function parses an array of permissions through a hard coded set of rules that have to be in consens with the
 * sender of the JWT token.
 *
 * @param permissionArray An array of permission strings.
 */
export function parsePermissions(permissionArray: string[]): JWTPermissions {
  const projectIds = cleanArray(
    permissionArray.map(perm => {
      const searchTerm = 'emmyportal_job_'
      return perm.startsWith(searchTerm) ? perm.replaceAll(searchTerm, '') : undefined
    })
  )
  const hasEmmyBiAccess = permissionArray.includes('emmyportal_bi')
  const hasAdminAccess = permissionArray.includes('adminconsole_access')
  const hasAccessToAllProjects = permissionArray.includes('emmyportal_project_id_view_all')

  const permissions: JWTPermissions = {
    portal: {
      wildcard: hasAccessToAllProjects,
      projectIds: projectIds
    },
    bi: {
      enabled: hasEmmyBiAccess
    },
    admin: {
      enabled: hasAdminAccess
    }
  }

  return permissions
}
