<template>
  <ion-list
    v-if="activeSegment == 'project' && getFilteredProject"
    class="scroll-fix"
    :class="{
      'max-height': !isOverlay && !isEmmy,
      'max-height-emmyTalk': !isOverlay && isEmmy,
      emmytalkFullscreen: emmytalkFullscreen
    }"
  >
    <ion-item v-for="message in getFilteredProject" :key="message.id">
      <ProjectMessageOverview :message="message" :is-overlay="isOverlay" />
    </ion-item>
  </ion-list>

  <ion-list
    v-if="activeSegment == 'news' && getFilteredProject"
    class="scroll-fix"
    :class="{
      'max-height': !isOverlay,
      emmytalkFullscreen: emmytalkFullscreen
    }"
  >
    <ion-item v-for="message in getFilteredProject" :key="message.id" :class="{ 'max-height': !isOverlay }">
      <NewsMessageOverview :message="message" :is-overlay="isOverlay" />
    </ion-item>
  </ion-list>

  <ion-list
    v-if="activeSegment != 'candidates' && activeSegment != 'project' && getFilteredProject"
    class="scroll-fix"
    :class="{
      'max-height': !isOverlay,
      emmytalkFullscreen: emmytalkFullscreen
    }"
  >
    <ion-item v-for="message in getFilteredProject" :key="message.id">
      <MessageOverview :message="message" :is-overlay="isOverlay" />
    </ion-item>
  </ion-list>

  <ion-list
    v-if="activeSegment == 'candidates' && getFilteredProject"
    class="scroll-fix"
    :class="{
      'max-height': !isOverlay,
      emmytalkFullscreen: emmytalkFullscreen
    }"
  >
    <ion-item v-for="message in getFilteredProject" :key="message.id">
      <CandidateMessageOverview :message="message" :is-overlay="isOverlay" />
    </ion-item>
  </ion-list>
</template>

<script>
import { IonList, IonItem } from '@ionic/vue'

import MessageOverview from './MessageOverview.vue'
import NewsMessageOverview from './NewsMessageOverview.vue'
import CandidateMessageOverview from './CandidateMessageOverview.vue'
import ProjectMessageOverview from './ProjectMessageOverview.vue'

import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'MessageOverviews',
  components: {
    IonList,
    IonItem,
    MessageOverview,
    NewsMessageOverview,
    CandidateMessageOverview,
    ProjectMessageOverview
  },
  props: { activeSegment: String, isOverlay: Boolean },
  setup() {
    const store = useStore()

    return {
      unfilteredMessages: computed(() => store.getters.getFilteredMessages),
      marketNews: computed(() => store.getters.getFilteredMarketNews),
      projects: computed(() => store.getters.getFilteredProjects),
      candidates: computed(() => store.getters.getFilteredCandidates),
      lastOpenendCandidates: computed(() => store.getters.getLastOpenendCandidates),
      filterBy: computed(() => store.state.filterMessagesBy),
      emmytalkFullscreen: computed(() => store.state.emmytalkFullscreen)
    }
  },
  computed: {
    isEmmy() {
      if (this.$route.name === 'Emmytalk') {
        return true
      }
      return false
    },

    getFilteredProject: function () {
      if (this.activeSegment === 'project') {
        const steps = [
          'Briefing',
          'Auswahlprozess',
          'Profilvorstellung',
          'Interviewphase',
          'Vertragsverhandlung',
          'Platzierung'
        ]

        const unsorted = this.projects
        const sorted = unsorted.sort(function (x, y) {
          var xStepCount = 0
          steps.map((step, index) => {
            if (step === x.pathCommunity) {
              xStepCount = index
            }
          })

          var yStepCount = 0
          steps.map((step, index) => {
            if (step === y.pathCommunity) {
              yStepCount = index
            }
          })

          if (xStepCount > yStepCount) {
            return -1
          }
          if (xStepCount < yStepCount) {
            return 1
          }
        })
        return sorted
      }

      if (this.activeSegment === 'news') {
        return this.marketNews
      }

      if (this.activeSegment === 'candidates') {
        const unsorted = this.candidates
        const sorted = unsorted.sort(function (x, y) {
          if (
            new Date(x.stepActions[x.stepActions.length - 1].timestamp) >
            new Date(y.stepActions[y.stepActions.length - 1].timestamp)
          ) {
            return -1
          }
          if (
            new Date(x.stepActions[x.stepActions.length - 1].timestamp) <
            new Date(y.stepActions[y.stepActions.length - 1].timestamp)
          ) {
            return 1
          }
        })

        return sorted
      }

      const results = this.unfilteredMessages

      const sorted = results.sort(function (x, y) {
        var createdX
        var createdY

        if (
          x.comments != undefined &&
          x.comments.length > 0 &&
          x.comments[x.comments.length - 1].createdDate != undefined
        ) {
          createdX = x.comments[x.comments.length - 1].createdDate
        } else {
          createdX = x.createdDate
        }

        if (
          y.comments != undefined &&
          y.comments.length > 0 &&
          y.comments[y.comments.length - 1].createdDate != undefined
        ) {
          createdY = y.comments[y.comments.length - 1].createdDate
        } else {
          createdY = y.createdDate
        }

        if (createdX > createdY) {
          return -1
        }
        if (createdX < createdY) {
          return 1
        }
      })
      return sorted
    }
  }
}
</script>

<style scoped lang="scss">
ion-list::-webkit-scrollbar {
  width: 8px;
}
ion-list::-webkit-scrollbar-track {
  background-color: transparent;
  margin-right: 60px;
  border-radius: 10px;
}
ion-list::-webkit-scrollbar-thumb {
  background-color: #ededf0;
  opacity: 0.3;
  border-radius: 10px;
  height: 140px;
}

ion-list {
  margin-top: 3px;
  padding: 0 0 30px;
  background: transparent;
  overflow-y: overlay;
  height: calc(80vh - 200px);
  max-height: 80vh;

  ion-item {
    --background: transparent;
    --inner-border-width: 0;
  }
}

@media (max-width: 899px) {
  ion-list {
    height: calc(100vh - 560px);
    max-height: 100vh;
  }

  .emmytalkFullscreen {
    min-height: calc(100vh - 170px);
    max-height: calc(100vh - 170px);
  }
}

.max-height {
  height: 651px;
  // height: calc(100vh - 367px); /* Try and error :( */
}

/**
 * Fixes #160
 */
.scroll-fix {
  overflow-y: scroll;
}
</style>
