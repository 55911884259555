<template>
  <div class="relative flex flex-row items-center gap-3">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.relative {
  position: relative;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.items-center {
  align-items: center;
}

.gap-3 {
  gap: 1rem;
}
</style>
