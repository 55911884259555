<template>
  <div ref="notification" class="notification animate" :class="notificationClass">
    <div class="bar" :style="{ ...autoDismissBarAnimationDuration }" :class="barClass" />
    <HFlex class="content">
      {{ message.text }}
      <FlexSpacer />
      <i-emmy-cancle class="cursor-pointer text-white/primary hover:text-white/2" @click="dismiss" />
    </HFlex>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import { Message } from '@/models/Message'

import HFlex from '@/components/flex/HFlex.vue'
import FlexSpacer from '@/components/flex/FlexSpacer.vue'

const props = defineProps({
  dismissable: {
    type: Boolean,
    default: true
  },
  message: {
    type: Object as PropType<Message>,
    required: true
  }
})

const { dismissable, message } = toRefs(props)

const emit = defineEmits(['dismiss'])

const notification = ref<HTMLElement>()

const durationS = computed(() => message.value.autoDismiss)
const durationMS = computed(() => (durationS.value ? durationS.value * 1000 : undefined))
const hasAutoDismiss = computed(() => (message.value.autoDismiss ? message.value.autoDismiss > 0 : false))
const flavour = computed(() => message.value.type.toLowerCase())
const notificationClass = computed(() => {
  return {
    [flavour.value]: true,
    autoDismiss: hasAutoDismiss.value
  }
})
const barClass = computed(() => {
  return {
    [flavour.value === 'default' ? 'bar-light' : 'bar-dark']: true
  }
})
const autoDismissBarAnimationDuration = computed(() => {
  return {
    ...(durationS.value !== undefined ? { animationDuration: `${durationS.value}s` } : {})
  }
})

onMounted(() => {
  if (hasAutoDismiss.value) {
    setTimeout(() => {
      dismiss()
    }, durationMS.value)
  }
})

const dismiss = () => {
  console.log('dismissing')

  if (!notification.value) return

  notification.value.classList.add('animate-out')
  setTimeout(() => {
    emit('dismiss')
  }, 300)
}
</script>

<style lang="scss" scoped>
.notification {
  @apply relative overflow-hidden rounded-lg border-2 border-black/1 bg-black/primary text-[14px] font-bold text-white/primary shadow-xl;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}

.notification.animate {
  animation: fadeIn 0.5s ease-in-out forwards;
}

.notification.animate-out {
  animation: fadeOut 0.5s ease-in-out forwards;
}

.notification.success {
  @apply border-2 border-green/light bg-green/primary text-white/primary;
}

.notification.error {
  @apply border-2 border-red/light bg-red/primary text-white/primary;
}

.notification.warning {
  @apply border-2 border-yellow/light bg-yellow/primary text-white/primary;
}

.notification.info {
  @apply border-2 border-blue/light bg-blue/primary text-white/primary;
}

.content {
  @apply py-2 px-6;
}

.bar {
  animation-name: timebar;
  animation-timing-function: linear;
  animation-direction: forwards;
  @apply absolute h-full;
}

.bar-light {
  opacity: 30%;
  @apply bg-white/primary;
}

.bar-dark {
  opacity: 10%;
  @apply bg-black/primary;
}

.cursor-pointer {
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes timebar {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
</style>
