<template>
  <ion-avatar :style="{ height: size + 'px', width: size + 'px' }">
    <img v-if="photo" :src="photo" :style="{ height: size + 'px', width: size + 'px' }" />

    <img v-if="documents" :src="getAvatarImage" :style="{ height: size + 'px', width: size + 'px' }" />

    <img
      v-if="!photo && !documents"
      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjYzVkYmZmIiBkPSJNMCAwaDUxMnY1MTJIMHoiLz48cGF0aCBkPSJNMjU2IDMwNGM2MS42IDAgMTEyLTUwLjQgMTEyLTExMlMzMTcuNiA4MCAyNTYgODBzLTExMiA1MC40LTExMiAxMTIgNTAuNCAxMTIgMTEyIDExMnptMCA0MGMtNzQuMiAwLTIyNCAzNy44LTIyNCAxMTJ2NTZoNDQ4di01NmMwLTc0LjItMTQ5LjgtMTEyLTIyNC0xMTJ6IiBmaWxsPSIjODJhZWZmIi8+PC9zdmc+"
      :style="{ height: size + 'px', width: size + 'px' }"
    />
  </ion-avatar>
</template>

<script>
import { IonAvatar } from '@ionic/vue'

export default {
  name: 'Avatar',
  components: { IonAvatar },

  props: {
    photo: String,
    documents: Array,
    size: {
      type: Number
    }
  },
  computed: {
    getAvatarImage: function () {
      if (this.documents.length != 0) {
        var photo = this.documents.find(document => document.type == 'photo')

        if (photo != undefined) {
          return 'data:image/jpeg;base64,' + photo.data
        }
      }

      return 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjYzVkYmZmIiBkPSJNMCAwaDUxMnY1MTJIMHoiLz48cGF0aCBkPSJNMjU2IDMwNGM2MS42IDAgMTEyLTUwLjQgMTEyLTExMlMzMTcuNiA4MCAyNTYgODBzLTExMiA1MC40LTExMiAxMTIgNTAuNCAxMTIgMTEyIDExMnptMCA0MGMtNzQuMiAwLTIyNCAzNy44LTIyNCAxMTJ2NTZoNDQ4di01NmMwLTc0LjItMTQ5LjgtMTEyLTIyNC0xMTJ6IiBmaWxsPSIjODJhZWZmIi8+PC9zdmc+'
    }
  }
}
</script>

<style scoped lang="scss">
ion-avatar {
  margin: 0;
  height: 50px;
  width: 50px;
  --border-radius: 100px;

  img {
    width: 50px;
    height: 50px;
    max-width: none;
    cursor: pointer;
  }
}
</style>
