<script lang="ts" setup>
import { computed, onMounted, ref, toRefs, watch } from 'vue'

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: true
  }
})

const { isLoading } = toRefs(props)
const initialLoad = ref(true)

const frameRate = 20 // 60fps

const x = ref(0)
let interval: NodeJS.Timer | undefined = undefined

watch(isLoading, value => {
  if (!interval) return

  if (!value) {
    // Finished loading
    clearInterval(interval)
  } else {
    interval = setInterval(() => {
      x.value += 1
    }, Math.round(1000 / frameRate))

    x.value = 0
  }
})

// start the interval

onMounted(() => {
  interval = setInterval(() => {
    x.value += 1
  }, Math.round(1000 / frameRate))

  setTimeout(() => {
    initialLoad.value = false
  }, 200)
})

const seconds = computed(() => {
  return Math.round(x.value / frameRate)
})

const progress = computed(function () {
  if (initialLoad.value) {
    return 0
  }

  if (!isLoading.value) {
    return 100
  }

  if (seconds.value <= 4) {
    return (0.7 - Math.exp((-x.value / frameRate) * 2)) * 100
  } else if (seconds.value <= 6) {
    return 72
  } else if (seconds.value <= 12) {
    return 73
  } else if (seconds.value <= 18) {
    return 80
  } else if (seconds.value <= 24) {
    return 85
  } else {
    return 90
  }
})

const longTransition = computed(() => {
  return seconds.value > 8 || !isLoading.value
})
</script>

<template>
  <div id="progress">
    <div
      class="progress-bar"
      :class="{ 'long-transition': longTransition, finished: !isLoading }"
      :style="{ width: `${progress}%` }"
    ></div>
  </div>
</template>

<style lang="scss" scoped>
#progress {
  position: absolute;
  width: 100%;
  height: 4px;
  overflow: hidden;
  z-index: 100;
  user-select: none;
  pointer-events: none;
}

.progress-bar {
  height: 100%;
  background-color: var(--background-selected);
  transition: all linear 300ms;
  // animation: lightPulse 1s ease-in-out infinite;
}

.long-transition {
  transition: all ease-in-out 1500ms;
}

.finished {
  animation: fadeOut 1s ease-in-out forwards;
  animation-delay: 1.3s;
}

@keyframes lightPulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
