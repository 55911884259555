<template>
  <ion-menu
    v-show="$route.name !== 'Login'"
    content-id="main-content"
    type="overlay"
    swipe-gesture
    auto-hide="true"
    class="main-menu"
  >
    <ion-header :translucent="true">
      <ion-toolbar class="header__sidebar">
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>

        <EsLogo class="desktop__logo" />
      </ion-toolbar>

      <ion-toolbar class="header__sidebar header__sidebar-mobile">
        <div class="mobile-search">
          <Searchbar v-if="isOpenSearch" class="mobile-search" />
        </div>
        <ion-buttons slot="end">
          <ion-menu-button v-if="!isOpenSearch"></ion-menu-button>
        </ion-buttons>

        <EsLogo v-if="!isOpenSearch" slot="start" class="mobile-logo" />
      </ion-toolbar>
    </ion-header>

    <ion-content class="sidebar" style="height: 100%">
      <ion-list id="nav-list" style="height: 100%; padding: 0 !important">
        <ion-menu-toggle auto-hide="false" style="height: 100%">
          <div class="scroll-container">
            <div class="vstack">
              <ion-item
                router-direction="root"
                router-link="/"
                lines="none"
                detail="false"
                class="hydrated"
                replace-true
                :class="{ selected: currentRoute === 'Home' }"
              >
                <svg class="icon" width="26" height="26" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.0874 17.6625H12.2905C11.4468 17.6625 10.7437 16.9594 10.7437 16.1156V13.0219C10.7437 12.7125 10.4905 12.4594 10.1812 12.4594H7.84678C7.5374 12.4594 7.28428 12.7125 7.28428 13.0219V16.0875C7.28428 16.9313 6.58115 17.6344 5.7374 17.6344H1.88428C1.04053 17.6344 0.337402 16.9313 0.337402 16.0875V6.27189C0.337402 5.85001 0.562402 5.45626 0.928027 5.23126L8.35303 0.534387C8.74678 0.281262 9.28115 0.281262 9.6749 0.534387L17.0999 5.23126C17.4655 5.45626 17.6624 5.85001 17.6624 6.27189V16.0594C17.6624 16.9594 16.9593 17.6625 16.0874 17.6625ZM7.81865 11.475H10.153C10.9968 11.475 11.6999 12.1781 11.6999 13.0219V16.0875C11.6999 16.3969 11.953 16.65 12.2624 16.65H16.0874C16.3968 16.65 16.6499 16.3969 16.6499 16.0875V6.30001C16.6499 6.21564 16.5937 6.13126 16.5374 6.07501L9.14053 1.37814C9.05615 1.32189 8.94365 1.32189 8.8874 1.37814L1.4624 6.07501C1.37803 6.13126 1.3499 6.21564 1.3499 6.30001V16.1156C1.3499 16.425 1.60303 16.6781 1.9124 16.6781H5.7374C6.04678 16.6781 6.2999 16.425 6.2999 16.1156V13.0219C6.27178 12.1781 6.9749 11.475 7.81865 11.475Z"
                  />
                </svg>

                <ion-label>{{ $t('sidebar.home') }}</ion-label>
              </ion-item>

              <ion-item
                lines="none"
                detail="false"
                router-direction="root"
                router-link="/projects"
                class="hydrated"
                :class="{
                  selected: currentRoute === 'Projects' || currentRoute === 'Project' || currentRoute === 'Candidate'
                }"
                @click="toggleSubmenu()"
              >
                <svg class="icon" width="26" height="26" viewBox="0 0 62 46" xmlns="http://www.w3.org/2000/svg">
                  ^
                  <path
                    d="M55.7 8.3H43C42 3.9 37.6 0.5 32.4 0.5H29.4C24.0999 0.5 19.6999 3.9 18.7999 8.3H5.99995C2.79995 8.3 0.199951 10.9 0.199951 14.1V39.8C0.199951 43 2.79995 45.6 5.99995 45.6H55.7C58.9 45.6 61.5 43 61.5 39.8V14C61.5 10.9 58.9 8.3 55.7 8.3ZM29.4 4H32.4C35.7 4 38.5 5.8 39.4 8.3H22.4C23.2999 5.8 26.1 4 29.4 4ZM5.99995 11.8H55.7C56.9 11.8 58 12.8 58 14.1V22L32.9 26.6C32.5 26.7 31.9999 26.7 31.5999 26.6L3.79995 21.4V14C3.79995 12.8 4.79995 11.8 5.99995 11.8ZM55.7 42H5.99995C4.79995 42 3.69995 41 3.69995 39.7V25L30.9 30C31.2999 30.1 31.8 30.1 32.2 30.1C32.6 30.1 33.0999 30.1 33.5 30L58 25.5V39.7C58 41 57 42 55.7 42Z"
                  />
                </svg>
                <ion-label class="desktop">{{ $t('sidebar.projects') }}</ion-label>
                <ion-label class="mobile">{{ $t('sidebar.projectsOverview') }}</ion-label>
              </ion-item>
              <ion-list
                v-if="(projects && isOpen && projects.length > 0) || (projects && isMobile && projects.length > 0)"
                class="sidebar__projects"
              >
                <ion-item
                  v-for="project in projectsList()"
                  :key="project.id"
                  :router-link="/projects/ + project.id"
                  :class="{
                    active: currentProjectRoute(project.id) || candidate === project.id
                  }"
                  class="highlightable"
                  style="position: relative; display: flex; flex-direction: row; width: 100%"
                >
                  <ProjectColor :color="project.color" />
                  <div style="display: flex; flex-direction: column; max-width: max-content">
                    <div class="wrap-text" style="font-size: 12px">{{ project.name }}</div>
                    <div class="wrap-text" style="font-size: 12px">{{ project.clientName }}</div>
                  </div>
                </ion-item>
                <ion-item class="wrap-text highlightable" router-link="/projects">
                  <div style="display: flex; flex-direction: column">
                    <div class="wrap-text show-all-projects" style="font-size: 14px">
                      {{ $t('sidebar.allProjects') }}
                    </div>
                  </div>
                </ion-item>
              </ion-list>

              <ion-item
                router-direction="root"
                router-link="/candidates"
                lines="none"
                detail="false"
                class="hydrated"
                replace-true
                :class="{ selected: currentRoute === 'Candidates' }"
              >
                <svg class="icon" width="26" height="26" viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 9.375C12.5625 9.375 14.6563 7.28125 14.6563 4.71875C14.6563 2.15625 12.5625 0.03125 10 0.03125C7.4375 0.03125 5.34375 2.125 5.34375 4.6875C5.34375 7.25 7.4375 9.375 10 9.375ZM10 1.125C11.9688 1.125 13.5625 2.71875 13.5625 4.6875C13.5625 6.65625 11.9688 8.25 10 8.25C8.03125 8.25 6.4375 6.65625 6.4375 4.6875C6.4375 2.75 8.03125 1.125 10 1.125Z"
                  />
                  <path
                    d="M19.4063 15C16.8125 12.7813 13.4688 11.5625 10 11.5625C6.53129 11.5625 3.18755 12.7813 0.593795 15C0.343795 15.1875 0.312545 15.5313 0.531295 15.7813C0.718795 16 1.06254 16.0312 1.31254 15.8437C3.71879 13.8125 6.81254 12.6875 10.0313 12.6875C13.25 12.6875 16.3438 13.8125 18.75 15.8437C18.8438 15.9375 18.9688 15.9688 19.0938 15.9688C19.25 15.9688 19.4063 15.9063 19.5 15.7813C19.6875 15.5313 19.6563 15.1875 19.4063 15Z"
                  />
                </svg>

                <ion-label>{{ $t('sidebar.candidates') }}</ion-label>
              </ion-item>

              <ion-item
                v-if="!isMobileSafari"
                detail="false"
                lines="none"
                class="hydrated"
                replace-true
                :class="{
                  selected:
                    currentRoute === 'Report-Aktuell' ||
                    currentRoute === 'Report-Verlauf' ||
                    currentRoute === 'Report-Zielfirmen'
                }"
                @click.stop="toggleReportMenu()"
              >
                <svg class="icon" width="26" height="26" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M46.3002 13.3999C43.0002 13.3999 40.3002 16.0999 40.3002 19.3999C40.3002 19.6999 40.3002 19.9999 40.4002 20.3999L37.5002 22.2999L34.3002 21.2999C33.6002 18.6999 31.3002 16.7999 28.5002 16.7999C25.2002 16.7999 22.5002 19.4999 22.5002 22.7999C22.5002 23.3999 22.6002 24.0999 22.8002 24.5999C21.9002 25.2999 20.9002 26.0999 19.9002 26.7999C18.9002 25.9999 17.6002 25.4999 16.2002 25.4999C12.9002 25.4999 10.2002 28.1999 10.2002 31.4999C10.2002 34.7999 12.9002 37.4999 16.2002 37.4999C19.5002 37.4999 22.2002 34.7999 22.2002 31.4999C22.2002 30.8999 22.1002 30.2999 21.9002 29.6999C22.8002 28.9999 23.8002 28.1999 24.8002 27.3999C25.8002 28.1999 27.1002 28.6999 28.5002 28.6999C31.1002 28.6999 33.2002 27.0999 34.1002 24.7999L36.6002 25.5999C36.9002 25.6999 37.2002 25.7999 37.6002 25.7999C38.2002 25.7999 38.8002 25.5999 39.3002 25.2999L42.0002 23.5999C43.1002 24.7999 44.6002 25.4999 46.4002 25.4999C49.7002 25.4999 52.4002 22.7999 52.4002 19.4999C52.4002 16.1999 49.6002 13.3999 46.3002 13.3999ZM16.2002 33.8999C14.8002 33.8999 13.7002 32.7999 13.7002 31.3999C13.7002 29.9999 14.8002 28.8999 16.2002 28.8999C17.6002 28.8999 18.7002 29.9999 18.7002 31.3999C18.7002 32.7999 17.6002 33.8999 16.2002 33.8999ZM28.5002 25.0999C27.1002 25.0999 26.0002 23.9999 26.0002 22.5999C26.0002 21.1999 27.1002 20.0999 28.5002 20.0999C29.9002 20.0999 31.0002 21.1999 31.0002 22.5999C31.0002 23.9999 29.9002 25.0999 28.5002 25.0999ZM46.3002 21.8999C44.9002 21.8999 43.8002 20.7999 43.8002 19.3999C43.8002 17.9999 44.9002 16.8999 46.3002 16.8999C47.7002 16.8999 48.8002 17.9999 48.8002 19.3999C48.8002 20.7999 47.7002 21.8999 46.3002 21.8999Z"
                  />
                  <path
                    d="M55.4002 6.3999H8.6002C4.5002 6.3999 1.2002 9.6999 1.2002 13.7999V36.9999C1.2002 41.0999 4.5002 44.3999 8.6002 44.3999H30.2002V54.1999H18.8002C17.8002 54.1999 17.0002 54.9999 17.0002 55.9999C17.0002 56.9999 17.8002 57.7999 18.8002 57.7999H45.2002C46.2002 57.7999 47.0002 56.9999 47.0002 55.9999C47.0002 54.9999 46.2002 54.1999 45.2002 54.1999H33.8002V44.3999H55.4002C59.5002 44.3999 62.8002 41.0999 62.8002 36.9999V13.6999C62.8002 9.6999 59.5002 6.3999 55.4002 6.3999ZM59.3002 36.8999C59.3002 38.9999 57.6002 40.7999 55.4002 40.7999H8.6002C6.5002 40.7999 4.7002 39.0999 4.7002 36.8999V13.6999C4.7002 11.5999 6.4002 9.7999 8.6002 9.7999H55.4002C57.5002 9.7999 59.3002 11.4999 59.3002 13.6999V36.8999Z"
                  />
                </svg>

                <ion-label>{{ $t('sidebar.emmybi.title') }}</ion-label>
              </ion-item>

              <ion-list v-if="isOpenReport" class="sidebar__projects">
                <ion-item
                  router-link="/emmy-bi/aktuell"
                  lines="none"
                  :class="{ active: currentReportRoute() === 'Report-Aktuell' }"
                  class="wrap-text"
                >
                  {{ $t('sidebar.emmybi.current') }}
                </ion-item>
                <ion-item
                  router-link="/emmy-bi/verlauf"
                  lines="none"
                  :class="{ active: currentReportRoute() === 'Report-Verlauf' }"
                  class="wrap-text"
                >
                  {{ $t('sidebar.emmybi.history') }}
                </ion-item>
                <ion-item
                  router-link="/emmy-bi/zielfirmen"
                  lines="none"
                  :class="{
                    active: currentReportRoute() === 'Report-Zielfirmen'
                  }"
                  class="wrap-text"
                >
                  {{ $t('sidebar.emmybi.targetCompanies') }}
                </ion-item>
              </ion-list>

              <ion-item
                router-direction="root"
                router-link="/emmytalk"
                lines="none"
                detail="false"
                class="hydrated emmytalk"
                replace-true
                :class="{ selected: currentRoute === 'Emmytalk' }"
              >
                <svg class="icon" width="26" height="26" viewBox="0 0 23 17" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20.4124 0.115479H2.5874C1.40146 0.115479 0.431152 1.14449 0.431152 2.40216V14.5978C0.431152 15.4363 0.826465 16.1604 1.50928 16.5796C1.83271 16.7702 2.22803 16.8845 2.5874 16.8845C2.94678 16.8845 3.30615 16.7702 3.66553 16.5796L6.82803 14.6359C6.97178 14.5597 7.11553 14.5216 7.25928 14.5216H20.3765C21.5624 14.5216 22.5327 13.4926 22.5327 12.2349V2.40216C22.5687 1.14449 21.5983 0.115479 20.4124 0.115479ZM21.3108 12.2349C21.3108 12.7685 20.9155 13.1877 20.4124 13.1877H7.29521C6.93584 13.1877 6.54053 13.302 6.21709 13.4926L3.05459 15.4363C2.76709 15.5887 2.44365 15.5887 2.15615 15.4363C1.86865 15.2838 1.7249 14.9789 1.7249 14.6359V2.40216C1.7249 1.8686 2.12022 1.44938 2.62334 1.44938H20.4483C20.9515 1.44938 21.3468 1.8686 21.3468 2.40216V12.2349H21.3108Z"
                  />
                  <path
                    d="M16.064 4.68884H6.43276C6.07339 4.68884 5.78589 4.99373 5.78589 5.37485C5.78589 5.75596 6.07339 6.06085 6.43276 6.06085H16.1C16.4593 6.06085 16.7468 5.75596 16.7468 5.37485C16.7468 4.99373 16.4234 4.68884 16.064 4.68884Z"
                  />
                  <path
                    d="M14.5546 8.99545H6.43276C6.07339 8.99545 5.78589 9.30034 5.78589 9.68146C5.78589 10.0626 6.07339 10.3675 6.43276 10.3675H14.5906C14.95 10.3675 15.2375 10.0626 15.2375 9.68146C15.2375 9.30034 14.914 8.99545 14.5546 8.99545Z"
                  />
                </svg>

                <ion-label>{{ $t('sidebar.emmyTalk') }}</ion-label>
              </ion-item>

              <div class="divider"></div>

              <!-- <ion-item
              v-if="baseUrls.admin"
              router-direction="root"
              lines="none"
              detail="false"
              class="hydrated emmycore"
              replace-true
              @click="openLink(baseUrls.admin)"
            >
              <div class="flex">
                <img src="/static/emmyadmin-logo.svg" class="solution-logo" alt="EmmySoft Admin Link" />
                <img src="/static/external-link.svg" class="external-link" alt="External Link" />
              </div>
            </ion-item> -->
            </div>
            <ion-item
              v-if="baseUrls.core && haveAccessToCore"
              router-direction="root"
              lines="none"
              detail="false"
              class="hydrated emmycore"
              replace-true
              @click="openLink(baseUrls.core)"
            >
              <div class="flex">
                <img src="/static/emmycore-logo.svg" class="solution-logo" alt="EmmyCore Link" />
                <img src="/static/external-link.svg" class="external-link" alt="External Link" />
              </div>
            </ion-item>

            <ion-item
              v-if="baseUrls.score && haveAccessToScore"
              router-direction="root"
              lines="none"
              detail="false"
              class="hydrated emmyscore"
              replace-true
              @click="openLink(baseUrls.score)"
            >
              <div class="flex">
                <img src="/static/emmyscore-logo.svg" class="solution-logo" alt="EmmyScore Link" />
                <img src="/static/external-link.svg" class="external-link" alt="External Link" />
              </div>
            </ion-item>
          </div>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script>
import EsLogo from '@/assets/es_logo.svg'
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonMenuButton,
  IonButtons,
  IonHeader,
  IonToolbar,
  popoverController
} from '@ionic/vue'
import { defineComponent, ref } from 'vue'

import { isPlatform } from '@ionic/vue'

import {
  paperPlaneOutline,
  paperPlaneSharp,
  briefcaseOutline,
  briefcaseSharp,
  homeOutline,
  peopleCircleOutline,
  peopleCircleSharp
} from 'ionicons/icons'

import ProjectColor from '@/components/ui-elements/ProjectColor.vue'
import PopoverContent from '@/components/ui-elements/PopoverContent.vue'

import Searchbar from '@/components/Searchbar.vue'

import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import { useConfigurationStore } from '@/pinia/configStore'

import { detect } from 'detect-browser'
import { useAuthStore } from '@/pinia/authStore'
import { Permission } from '@/models/Permission'
import { useLocalStore } from '@/pinia/localStore'

export default defineComponent({
  name: 'Sidebar',
  components: {
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonMenuButton,
    IonButtons,
    IonHeader,
    IonToolbar,
    ProjectColor,
    EsLogo,
    Searchbar
  },
  setup() {
    const store = useStore()
    const route = useRoute()

    const configStore = useConfigurationStore()
    const authStore = useAuthStore()
    const localStore = useLocalStore()

    const selected = ref(0)
    const selectedSubmenuItem = ref(null)

    const { lastOpenedProjectIds } = storeToRefs(localStore)

    return {
      store,
      authStore,
      configStore,
      localStore,
      selected,
      selectedSubmenuItem,
      lastOpenedProjectIds,
      paperPlaneOutline,
      paperPlaneSharp,
      briefcaseOutline,
      briefcaseSharp,
      homeOutline,
      peopleCircleOutline,
      peopleCircleSharp,
      projects: computed(() => store.state.projects),
      candidate: computed(() => store.getters.getProjectFromCandidate(route))
    }
  },
  data: function () {
    return {
      isOpen: false,
      isOpenAccount: false,
      isOpenReport: false,
      isOpenSearch: false,
      emmyBi: false
    }
  },
  computed: {
    isMobile: function () {
      if (isPlatform('tablet')) {
        return true
      }

      if (isPlatform('mobile')) {
        return true
      }

      return false
    },
    isMobileSafari() {
      return detect().name === 'safari' && detect().name === 'ios-webview'
    },
    currentRoute: function () {
      return this.$router.currentRoute.value.name
    },
    getProject: function () {
      return this.projects
    },
    baseUrls: function () {
      return this.configStore.getEnv().proxy?.baseUrl ?? {}
    },
    haveAccessToCore: function () {
      return this.authStore.checkUserHavePermission(Permission.EmmyCoreAccess)
    },
    haveAccessToScore: function () {
      return this.authStore.checkUserHavePermission(Permission.EmmyScoreAccess)
    }
  },
  created: function () {
    if (this.$route.params.id !== undefined) {
      if (!this.isOpen) {
        this.isOpen = true
      }
    }
  },
  methods: {
    async openPopoverProfile(ev) {
      const popover = await popoverController.create({
        component: PopoverContent,
        cssClass: 'my-custom-class',
        event: ev,
        translucent: true,
        showBackdrop: false
      })
      await popover.present()
    },

    projectsList: function () {
      if (typeof this.projects != 'undefined') {
        let projects = this.lastOpenedProjectIds
          .map(id => this.projects.find(project => project.id === id))
          .slice(0, 5)
          .filter(x => x !== undefined)

        if (projects.length < 5) {
          console.log(projects.length, 'Fill')

          const fillProjects = this.projects
            .filter(project => {
              const id = project.id
              return !this.lastOpenedProjectIds.includes(id)
            })
            .sort((a, b) => {
              const aDate = new Date(a.createdDate)
              const bDate = new Date(b.createdDate)
              return bDate - aDate
            })
            .slice(0, 5 - projects.length)

          console.log('fill projects', fillProjects)

          projects.push(...fillProjects)
        }

        return projects
      }
    },
    toggleAccount: function () {
      this.isOpenAccount = !this.isOpenAccount
    },

    toggleSubmenu: function () {
      this.isOpen = !this.isOpen
    },

    toggleReportMenu: function () {
      this.isOpenReport = !this.isOpenReport
    },

    toggleMobileSearch: function () {
      this.isOpenSearch = !this.isOpenSearch
    },

    currentProjectRoute: function (id) {
      if (this.$route.params.id === id && this.$route.name === 'Project') {
        if (!this.isOpen) {
          this.isOpen = true
        }
        return true
      }

      if (this.$route.name === 'Candidate') {
        if (!this.isOpen) {
          this.isOpen = true
        }
        return false
      }

      return false
    },

    currentReportRoute: function () {
      if (this.$route.name === 'emmy-bi') {
        if (!this.isOpen) {
          this.isOpen = true
        }
      }

      return this.$route.name
    },

    openLink: function (link) {
      window.open(link, '_blank')
    },

    openEmmyScore: function () {
      const host = window.location.host
      this.openLink('https://' + host.replace('portal', 'score'))
    }
  }
})
</script>

<style scoped lang="scss">
.main-menu {
  /**
   * The dragable sidebar caused the text to get highlighted,
   * this will disable selection for the menu.
   */
  user-select: none;
}

.sidebar__account {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
}

@media (min-width: 900px) {
  ion-menu {
    .header__sidebar {
      display: flex;
    }

    .header__sidebar-mobile {
      display: none;
    }
  }

  .mobile {
    display: none;
  }
}

@media (max-width: 899px) {
  ion-header {
    padding-top: env(safe-area-inset-top);
    background-color: #fff;
  }

  .emmytalk {
    display: none;
  }

  ion-menu {
    --width: 100% !important;

    .sidebar::part(background) {
      background: var(--background-secondary);
    }

    .header__sidebar img {
      max-height: 40px;
      text-align: center;
    }

    .sidebar ion-list.sidebar__projects {
      background: #ffffff;
      color: var(--font-secondary);

      .active {
        color: var(--font-sidebar-selected);
      }
    }

    .sidebar ion-item {
      padding-left: 16px;
      padding-right: 16px;
    }

    .sidebar ion-item.selected {
      --color: var(--font-sidebar-selected);
    }

    .sidebar ion-item.selected ion-label {
      color: var(--font-white);

      svg {
        path {
          fill: blue;
        }
      }
    }

    .sidebar ion-list.sidebar__projects ion-item.active::part(native) {
      color: var(--font-secondary) !important;
    }

    .header__sidebar {
      display: none;
    }

    .header__sidebar-mobile {
      display: flex;
      justify-content: space-between;
      height: 55px;
    }

    ion-buttons {
      margin-top: 4px;
    }

    .desktop {
      display: none;
    }
  }

  .mobile-logo {
    margin-left: 16px;
  }

  .mobile-search {
    margin-left: 16px;

    .searchbar-input-container {
      text-align: left;
    }
  }

  .mobile-search-cancel {
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    display: block;
    font-weight: bold;
    font-size: 16px;
    color: var(--font-title);
  }

  .mobile {
    display: block;
  }

  .mobile-emmybi {
    display: none;
  }
}

.sidebar::part(background) {
  background: var(--background-primary);
}

.sidebar::part(scroll) {
  padding: 0;
}

.sidebar ion-list:not(.sidebar__projects),
.sidebar ion-item {
  background: none;
  cursor: pointer;
}

.sidebar ion-item {
  border-radius: 0;
  cursor: pointer !important;

  &::part(native) {
    background: none;
  }

  .icon {
    margin-inline-end: 18px;
    color: #81a6ba;
    margin-top: 0;

    path {
      fill: #ffffff;
    }

    @media (max-width: 900px) {
      path {
        fill: #81a6ba;
      }
    }
  }

  ion-label {
    color: #81a6ba;
    font-size: 1.3rem;
    font-weight: 600;
    cursor: pointer;
  }

  @media (min-width: 900px) {
    ion-label {
      color: var(--font-white);
    }
  }

  &.selected {
    background: var(--background-sidebar-selected);

    path {
      fill: var(--font-sidebar-selected);
      opacity: 1;
    }

    @media (max-width: 900px) {
      path {
        fill: var(--font-sidebar-selected);
      }
    }

    ion-label {
      color: var(--font-sidebar-selected);
      cursor: pointer;
    }
  }
}

.sidebar ion-list.sidebar__projects {
  background: var(--background-dark);

  ion-item {
    cursor: pointer;
    --inner-border-width: 0;
    --min-height: 0;
    --padding-top: 10px;
    --padding-bottom: 10px;

    &.active::part(native) {
      color: var(--font-selected);
      font-weight: 600;
      cursor: pointer;
    }
  }

  ion-item::part(native) {
    color: #d2d2d2;
    font-size: 1.3rem;
    cursor: pointer;
  }

  @media (max-width: 899px) {
    ion-item::part(native) {
      color: #7e7e7e;
      font-size: 1.3rem;
      cursor: pointer;
    }
  }
}

.wrap-text {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

ion-header {
  image-rendering: -webkit-optimize-contrast;
}

ion-button {
  position: relative;
  margin: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-start: 0;
  --background: transparent;

  svg {
    margin-right: 10px;
    fill: #9a9eb3;
  }
}

.sidebar__account {
  margin-top: 36px;
  margin-bottom: 36px;
  align-items: center;
}

.arrow {
  width: 16px;
  height: 16px;
}

.title {
  margin-left: 10px;
  margin-right: 10px;
  display: block;
  font-weight: bold;
  font-size: 16px;
  color: var(--font-title);
}

.mobile-avatar {
  margin-left: 10px;
}

.desktop__logo {
  margin-top: 2px;
  margin-left: 12px;
}

// .divider {
//   margin: 12px 0;
//   height: 1px;
//   width: 100%;
//   background: rgba(255, 255, 255, 0.2);
// }

.solution-logo {
  height: 24px;
}

.emmycore,
.emmyscore {
  background-color: rgba(#fff, 1) !important;
}

.flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.external-link {
  width: 20px;
  height: 20px;
  opacity: 0.3;
  transition: all 0.1s ease-in-out;
}

.emmycore:hover .external-link {
  opacity: 0.9;
}

.emmyscore:hover .external-link {
  opacity: 0.9;
}

.vstack {
  width: 100%;
}

.divider {
  flex-grow: 1;
}

.show-all-projects {
  margin-left: 16px;
}

.highlightable {
  transition: all 0.1s ease-in-out;

  &:hover > * {
    color: #fff !important;
  }
}
</style>
