import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useLocalStore = defineStore('local', () => {
  /**
   * Indicates whether the application is currently loading.
   */
  const lastOpenedProjectIds = useLocalStorage('lastOpenedProjects', [] as string[])

  function addLastOpenedProjectId(id: string) {
    lastOpenedProjectIds.value = [id, ...lastOpenedProjectIds.value.filter(i => i !== id)]
  }

  return {
    lastOpenedProjectIds,
    addLastOpenedProjectId
  }
})
