import { computed, watch } from 'vue'
import { defineStore } from 'pinia'
import { toRefs, useLocalStorage } from '@vueuse/core'
import { OrganizationDataV2 } from '../models'
import { Amplify } from 'aws-amplify'
import { useAuthStore } from './authStore'
import { tryIt } from '@/lib/tryIt'

/**
 * The version of the configuration that is currently in use. The configuration reflects the version requested from the
 * configuration API (emmysoft-proxy). When the configuration changes this value should be incremented in order to
 * invalidate everyone's local storage and prevent migration issues. When the configuration has changed the user will be
 * signed out automatically.
 *
 * CHANGELOG:
 *
 * - V1: Initial version with ENV variable names
 * - V2: Nested object structure
 */
const currentConfigVersion = 2

export const useConfigurationStore = defineStore('configuration', () => {
  // ===========
  // Dependencies
  // ===========

  const authStore = useAuthStore()

  // ===========
  // Initialisation
  // ===========

  async function init() {
    console.log('🕹 Initializing configuration store...')

    if (isConfigured.value) {
      console.log('🕹 Load configuration from local storage')
      if (storageVersionHasChanged.value) {
        console.warn('🕹 Configuration version has changed. Clearing local storage')
        authStore.signOut()
        localStorage.clear()
      }

      await initializeAmplify()
      await initializeAuthStore()
    } else if (!env.value.local.isPinned) {
      console.log('🕹 Configuration not loaded. Waiting for user to sign in')
    }

    if (env.value.local.isPinned) {
      console.log('🕹 Organization ID is pinned to ' + env.value.local.id)
      try {
        await setOrganizationId(env.value.local.id!)
      } catch {
        clear()
      }
      console.log('🕹 Initialized')
      return
    }

    console.log('🕹 Initialized')
  }

  // ===========
  // State
  // ===========

  /**
   * When the pinned organization id is not valid, this variable will indicate to use a fallback to the proxy mode.
   */
  let useProxyFallback = ref(false)

  /**
   * Local storage for the configuration store.
   */
  const state = useLocalStorage('configuration-store', {
    /**
     * The version of the configuration that is currently being used. This is used to clear the local storage if the
     * configuration changes.
     */
    configurationVersion: currentConfigVersion,

    /**
     * The ID of the organization that is currently being used.
     */
    organizationId: null as string | null,

    /**
     * The configuration for the organization. This is loaded from the proxy server and is used to configure the
     * application.
     *
     * This object should not be used directly instead rely on the `env` computed value. Or use the equivalent
     * `getEnv()` function.
     */
    configuration: null as OrganizationDataV2 | null,

    // TODO: Use vue-i18n composition API
    /**
     * The locale that is currently being used. This is needed as this application still relies on the lagecy version of
     * vue-i18n and therefor the library can not be used in the composition API. Updating this variable will not update
     * the locale of the application.
     */
    locale: 'en',

    /**
     * When loading loading multiple organizations ids in quick succession, this variable will store the most recent
     * organization id that was requested. This is used to prevent the application from loading the wrong organization
     * id on slow connections where responses might arrive out of order.
     */
    mostRecentOrgIdLoad: undefined as string | undefined
  })

  const { configurationVersion, organizationId, configuration } = toRefs(state)

  // ===========
  // Computed
  // ===========

  /**
   * Returns a boolean indicating whether the configuration has been loaded.
   */
  const isConfigured = computed(() => configuration.value !== null && organizationId.value !== null)

  /**
   * Returns whether the configuration is valid.
   */
  const isValidOrganizationId = computed(() => {
    return organizationId.value !== null && configuration.value !== null
  })

  /**
   * The environment variables that are used to configure the application.
   */
  const env = computed(() => {
    return {
      local: {
        id: useProxyFallback.value ? undefined : (window as any).VUE_APP_ORGANIZATION_ID,
        isPinned: useProxyFallback.value ? false : (window as any).VUE_APP_ORGANIZATION_ID !== undefined,
        proxyServerURL: (window as any).VUE_APP_API_PROXY_SERVER_URL.replace(/'/g, ''),
        environment: (window as any).VUE_APP_ENVIRONMENT
      },
      proxy: configuration.value
    } as {
      local: { id: string | undefined; isPinned: boolean; proxyServerURL: string; environment: string }
      proxy: OrganizationDataV2 | null
    }
  })

  /**
   * Computed value that returns the ID of the organization that is currently being used.
   */
  const orgId = computed(() => {
    return organizationId.value || env.value.local.id
  })

  const storageVersionHasChanged = computed(() => configurationVersion.value !== currentConfigVersion)

  // ===========
  // Actions
  // ===========

  /**
   * Clears the confgiuration. This function will be called when the user logs out
   */
  function clear() {
    console.log('🕹 Clearing configuration.')
    configurationVersion.value = currentConfigVersion
    organizationId.value = null
    configuration.value = null
  }

  /**
   * Convenience function to get the environment variables as an object instead of a computed value. This is used for
   * easier integration with lagacy code. For new code, use the `env` computed value instead.
   */
  function getEnv() {
    return env.value
  }

  /**
   * Returns the AWS configuration object that can be used to configure Amplify.
   * `Amplify.configure(awsConfiguration.value)`
   */
  const getAwsConfiguration = function () {
    const config = configuration.value
    if (!config) return undefined

    return {
      Auth: {
        // REQUIRED - Amazon Cognito Region
        region: config.aws.region,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: config.aws.userPoolId,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: config.aws.userPoolClientId,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH'
      }
    }
  }

  /**
   * Returns the ID of the organization that is currently being used. This is to not interfere with the `organizationId`
   * state variable directly.
   */
  function getOrganizationId() {
    return organizationId.value
  }

  /**
   * Set a new organization ID. This will load the configuration from the proxy server. The function can be handle
   * multiple calls in quick succession. Only the last call will be used. This is to prevent clients on slow connections
   * from loading the wrong configuration.
   *
   * @param id The ID of the organization to load the configuration for.
   * @param force Whether to force the configuration to be loaded even though the ID has changed. (Default: `false`)
   * @returns A promise that resolves to the configuration.
   */
  async function setOrganizationId(id: string, force = false) {
    state.value.mostRecentOrgIdLoad = id

    const config = await loadConfigurationFromProxy(id)

    const recentOrgId = state.value.mostRecentOrgIdLoad

    if (!force && recentOrgId !== id) {
      throw new Error(`🕹 Organization ID changed while loading. (Requested: ${id}, New: ${recentOrgId})`)
    }

    organizationId.value = id
    console.log('🕹 Organization ID set to ' + id)

    return config
  }

  /**
   * Fetches the configuration from the proxy server.
   *
   * @param id The ID of the organization to load the configuration for.
   * @returns A promise that resolves to the configuration.
   */
  async function loadConfigurationFromProxy(id: string) {
    const version = configurationVersion.value
    const response = await tryIt(
      async () => await fetch(`${env.value.local.proxyServerURL}/api/v${version}/config/${id}`)
    )

    if (!response || !response.ok) {
      console.error('🕹 ❌ Configuration retrieval failed.')
      clear()

      if (env.value.local.isPinned) {
        console.error('🕹 ❌ Pinned organization ID is invalid. Falling back to proxy mode.')
        useProxyFallback.value = true
      }

      throw new Error('🕹 Invalid Organization ID')
    }

    const organizationData = (await response.json()) as OrganizationDataV2
    configuration.value = organizationData

    console.info('🕹 ✅ Configuration update retrieved from proxy server.')
    // Please print this only locally in development mode
    // const printableTableData = flatternObject(organizationData)
    // console.table(printableTableData)

    initializeAmplify()
    await initializeAuthStore()

    return organizationData
  }

  function initializeAmplify() {
    Amplify.configure(getAwsConfiguration())
  }

  async function initializeAuthStore() {
    return authStore.init()
  }

  /**
   * When a organization ID is set, this function will load the configuration from the proxy server.
   */
  async function updateConfiguration() {
    if (!organizationId.value) return
    return loadConfigurationFromProxy(organizationId.value)
  }

  /**
   * Getter for the current locale.
   */
  function getLocale() {
    return state.value.locale
  }

  /**
   * Setter for the current locale.
   */
  function setLocale(locale: string) {
    state.value.locale = locale
    authStore.setUserAttribute('locale', locale)
  }

  // ===========
  // Event Handlers
  // ===========

  watch(configurationVersion, (newValue, oldValue) => {
    if (newValue !== oldValue) {
      console.info('🕹 Configuration version changed. Clearing local storage.')
      clear()
    }
  })

  // ===========
  // Return
  // ===========

  return {
    init,
    configurationVersion,
    organizationId,
    isConfigured,
    isValidOrganizationId,
    setOrganizationId,
    getOrganizationId,
    getLocale,
    setLocale,
    clear,
    env,
    getEnv,
    orgId,
    updateConfiguration
  }
})
