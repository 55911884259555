<template>
  <div ref="viewer" :class="{ 'viewer-padding': isFullscreenDocumentAvailable }"></div>
</template>

<script>
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer'
import { useConfigurationStore } from '@/pinia/configStore'
import { usePdfViewerStore } from '@/pinia/pdfViewerStore'
import { storeToRefs } from 'pinia'
import { fetcher } from '@/http'
import apiConfig from '@/config/api'
import { Auth } from 'aws-amplify'

export default {
  name: 'WebViewer',
  props: {
    document: {
      type: String,
      required: true
    },
    documentId: {
      type: String,
      required: true
    }
  },
  setup() {
    const configurationStore = useConfigurationStore()
    const pdfViewerStore = usePdfViewerStore()

    const { env } = storeToRefs(configurationStore)
    const { fullscreenDocument, isFullscreenDocumentAvailable } = storeToRefs(pdfViewerStore)

    return { env, fullscreenDocument, isFullscreenDocumentAvailable, pdfViewerStore }
  },
  data() {
    return {
      // WebViewer instance
      /**
       * @type {WebViewerInstance | undefined}
       */
      viewer: undefined,
      userFullName: ''
    }
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      this.viewer.UI.setLanguage(newVal)
    },
    document: function (newVal, oldVal) {
      console.log('document changed', newVal)
      this.viewer.Core.documentViewer.loadDocument(newVal)
    }
  },
  mounted: function () {
    this.getUser()
    WebViewer(
      {
        path: window.BASE_URL + '/webviewer',
        licenseKey: this.env.proxy.pdfExpress.web.key,
        initialDoc: this.document,
        extension: 'pdf',
        annotationUser: this.userFullName
      },
      this.$refs.viewer
    ).then(instance => {
      this.viewer = instance

      instance.UI.disableElements(['saveAsButton', 'printButton'])

      instance.UI.updateElement('downloadButton', {
        onClick: () => {
          // open the document in a new tab
          window.open(this.document, '_blank')
        }
      })

      function adaptUI(width) {
        if (width < 600) {
          instance.UI.disableElements(['panToolButton'])
        } else {
          instance.UI.enableElements(['panToolButton'])
        }

        if (width <= 490) {
          instance.UI.disableElements(['zoomInButton', 'zoomOutButton', 'viewControlsButton'])
        } else {
          instance.UI.enableElements(['zoomInButton', 'zoomOutButton', 'viewControlsButton'])
        }

        if (width <= 720) {
          instance.UI.disableElements(['selectToolButton'])
        } else {
          instance.UI.enableElements(['selectToolButton'])
        }
      }

      window.addEventListener('resize', () => {
        const width = window.innerWidth
        adaptUI(width)
      })

      if (this.isFullscreenDocumentAvailable) {
        instance.UI.setHeaderItems(header => {
          header.push({
            type: 'actionButton',
            img: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>',
            onClick: () => {
              this.pdfViewerStore.dismiss()
            }
          })
        })
      } else {
        instance.UI.setHeaderItems(header => {
          header.push({
            type: 'actionButton',
            img: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-maximize-2"><polyline points="15 3 21 3 21 9"></polyline><polyline points="9 21 3 21 3 15"></polyline><line x1="21" y1="3" x2="14" y2="10"></line><line x1="3" y1="21" x2="10" y2="14"></line></svg>',
            onClick: () => {
              this.fullscreenDocument = this.document
            }
          })
        })
      }

      adaptUI(window.innerWidth)

      instance.UI.setLanguage(navigator.language)
      instance.UI.setToolbarGroup(instance.UI.ToolbarGroup.VIEW)

      const { documentViewer, annotationManager } = instance.Core

      documentViewer.addEventListener('documentLoaded', async () => {
        // perform document operations
        const xfdf = await this.getXfdfData(this.documentId)

        if (xfdf) {
          annotationManager
            .importAnnotations(xfdf)
            .then(async annotations => annotationManager.drawAnnotationsFromList(annotations))
        }
      })

      annotationManager.addEventListener('annotationChanged', async (annotations, action, { imported }) => {
        // If the event is triggered by importing then it can be ignored
        // This will happen when importing the initial annotations
        // from the server or individual changes from other users
        if (imported) {
          return
        }

        const xfdfString = await annotationManager.exportAnnotations()
        this.addXfdfData(this.documentId, xfdfString)
      })
    })
  },

  unmounted() {
    if (this.viewer) {
      this.viewer.dispose()
    }
  },
  methods: {
    async getXfdfData(documentId) {
      let xfdf = ''

      if (documentId) {
        console.log(
          'Will try to get XFDF data from backend, URL',
          apiConfig.resources.documents + '/' + documentId + '/annotation/'
        )

        const response = await fetcher(apiConfig.resources.documents + '/' + documentId + '/annotation/')

        /**
         * @type {{ xfdf: string }}
         */
        const data = await response.json()

        xfdf = data.xfdf

        console.log('XFDF data', xfdf)
      }

      return xfdf
    },
    addXfdfData(documentId, xfdfData) {
      if (documentId) {
        fetcher(apiConfig.resources.documents + '/' + documentId + '/annotation/', {
          method: 'POST',
          body: JSON.stringify({
            xfdf: xfdfData
          })
        }).catch(error => {
          console.error(error)
        })
      }
    },
    getUser: function () {
      Auth.currentSession()
        .then(data => {
          const idTokenPayload = data.getIdToken().payload
          this.userFullName = `${idTokenPayload.given_name} ${idTokenPayload.family_name}`
        })
        .catch(error => {
          console.error(error)
          this.userFullName = ''
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div {
  width: 100%;
  height: 100vw;
  max-height: 85vh;
}

.viewer-padding {
  background-color: #f8f9fa;
  padding-top: env(safe-area-inset-top);
}

:deep(iframe) {
  border: none;
}
</style>
