import { useAuthStore } from '../pinia/authStore'
import { useConfigurationStore } from '../pinia/configStore'

/**
 * By default the content-type is set to `application/json`. If you want to send a different content-type, you can set
 * it inside the init object.
 *
 * @param endpoint The endpoint of the API. This one automatically gets prefixed with the API base URL.
 * @param init - The init object is passed to the fetch function. Inside that object you can define additional headers,
 *   the method, the body, etc.
 * @returns The response of the fetch function
 */
export const fetcher = async (endpoint: string, init?: RequestInit | undefined, baseUrlOverwrite?: string) => {
  const authStore = useAuthStore()
  const configStore = useConfigurationStore()

  const env = configStore.getEnv()
  const locale = configStore.getLocale()

  const headers = await authStore.getRequestHeaders()

  // ... the deeper down the nested object is added the more likely it overwrites the previous value
  const modifiedInit = {
    ...init,
    headers: {
      // If the content-type is not set inside the init object, set it to JSON
      'Content-Type': 'application/json',
      'Accept-Language': locale,
      ...init?.headers,
      ...headers
    }
  }

  if (!env.proxy) throw new Error('No proxy configured while fetching data from the API')

  const url = baseUrlOverwrite ?? env.proxy.general.apiBaseUrl + ('/v4/' + endpoint).replace(/\/\//g, '/')

  try {
    const res = await fetch(url, modifiedInit)

    if (!res.ok) {
      console.error(await res.json())
      throw new Error(`Request to ${url} failed with status code ${res.status}`)
    }

    return res
  } catch (error) {
    throw error
  }
}
