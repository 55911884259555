/**
 * Will try to execute the function and will not throw any errors.
 *
 * @param fn The function to execute.
 */
export async function tryIt<T>(fn: () => Promise<T>): Promise<T | undefined> {
  try {
    return await fn()
  } catch {
    return undefined
  }
}
