<template>
  <teleport v-if="!homeRoute" to="body">
    <div
      class="overlay mobile"
      :class="{
        emmytalkOpen: emmyTalkOpen,
        emmytalkFullscreen: emmytalkFullscreen,
        [theme]: theme
      }"
      :style="{
        'padding-top': emmyTalkOpen && isMobile && emmytalkFullscreen ? '64px' : '0px'
      }"
    >
      <div v-for="overlayChat in getOverlayMessages" :key="overlayChat">
        <MessageOverlay :message-id="overlayChat" />
      </div>
      <Sidebar :is-overlay="true" />
    </div>
  </teleport>
</template>

<script>
import Sidebar from './components/Sidebar.vue'
import MessageOverlay from './components/MessageOverlay.vue'

import { useConfigurationStore } from '@/pinia/configStore'

import { isPlatform } from '@ionic/vue'
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  name: 'EmmytalkOverlay',
  components: {
    Sidebar,
    MessageOverlay
  },
  props: {
    chatAbout: String,
    startMinimized: Boolean
  },
  setup() {
    const store = useStore()
    const sidebarOpen = computed(() => store.state.overlaySidebar)
    const emmytalkFullscreen = computed(() => store.state.emmytalkFullscreen)
    const configStore = useConfigurationStore()

    return {
      sidebarOpen,
      emmytalkFullscreen,
      configStore
    }
  },
  data() {
    return {
      theme: this.configStore.getEnv().proxy?.general.theme ?? 'default'
    }
  },
  computed: {
    isMobile() {
      return isPlatform('mobile')
    },
    emmyTalkOpen() {
      if (this.sidebarOpen) {
        return true
      }

      return false
    },
    getOverlayMessages() {
      return this.$store.getters.getOverlayMessages
    },

    getMessage() {
      return this.$store.getters.getMessage
    },

    homeRoute: function () {
      if (this.$route.name === 'Home' && isPlatform('tablet')) {
        return false
      }

      if (this.$route.name === 'Home' && !isPlatform('desktop')) {
        return true
      }

      return this.$route.name === 'Emmytalk'
    }
  }
}
</script>

<style scoped lang="scss">
@media (min-width: 900px) {
  .overlay {
    position: fixed;
    bottom: 0;
    right: 10px;

    display: flex;
    align-items: flex-end;
  }
}

@media (max-width: 899px) {
  .overlay {
    padding: 0;
    background-color: var(--background-primary);
  }

  .mobile {
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  .emmytalkOpen {
    position: relative;
    margin-top: 100%;
  }

  .emmytalkFullscreen {
    margin-top: 0;
  }
}
</style>
