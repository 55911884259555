<template>
  <ion-searchbar
    :placeholder="$t('search.searchPlaceholder')"
    inputmode="search"
    type="search"
    enterkeyhint="search"
    autocomplete="off"
    autocorrect="off"
    @ion-change="handleSearch($event.target.value)"
  >
    <SearchIcon class="search-icon" />
  </ion-searchbar>

  <teleport to="body">
    <div v-if="isOpen" class="search-results">
      <ion-card>
        <ion-card-header>{{ $t('search.projects') }} </ion-card-header>
        <ion-item
          v-for="result in filteredProjects"
          :key="result"
          :router-link="/projects/ + result.id"
          @click="handleClick"
        >
          {{ result.name }}
        </ion-item>

        <ion-item v-if="!filteredProjects.length">
          {{ $t('search.projectsNotFound') }}
        </ion-item>

        <ion-card-header>{{ $t('search.candidates') }}</ion-card-header>
        <ion-item
          v-for="result in filteredCandidates"
          :key="result"
          :router-link="/candidates/ + result.id"
          @click="handleClick"
        >
          <Avatar :documents="result.documents" :size="64" />
          {{ result.candidateName }}
        </ion-item>

        <ion-item v-if="!filteredCandidates.length">
          {{ $t('search.candidatesNotFound') }}
        </ion-item>
      </ion-card>
    </div>
  </teleport>
</template>

<script>
import { IonSearchbar, IonCard, IonCardHeader, IonItem } from '@ionic/vue'

import Avatar from '@/components/ui-elements/Avatar.vue'

import SearchIcon from '@/assets/search.svg'

import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'Searchbar',
  components: { IonSearchbar, IonCard, IonCardHeader, IonItem, Avatar, SearchIcon },
  setup() {
    const store = useStore()

    return {
      projects: computed(() => store.state.projects)
    }
  },
  data: function () {
    return {
      input: '',
      isOpen: false,
      candidates: [],
      filteredProjects: [],
      filteredCandidates: []
    }
  },
  created() {},
  methods: {
    handleClick() {
      this.isOpen = false
      this.input = ''
    },

    handleSearch(data) {
      this.candidates = []

      this.filteredProjects = this.projects.filter(project => {
        return project.name.toLowerCase().includes(data.toLowerCase())
      })

      this.projects.map(project => {
        project.jobApplications.map(jobApplication => {
          this.candidates.push(jobApplication)
        })
      })

      this.filteredCandidates = this.candidates.filter(candidate => {
        return candidate.candidateName.toLowerCase().includes(data.toLowerCase())
      })

      this.isOpen = data.length > 0
    }
  }
}
</script>

<style scoped lang="scss">
ion-searchbar {
  border: 2px solid #ededf0;
  border-radius: 100px;
  --box-shadow: none;
  --border-radius: 50px;
}

ion-card-header {
  font-size: 1.8rem;
  font-weight: 600;
}

.search-results {
  background: rgba(23, 77, 121, 0.5);
  height: 100%;
  --min-width: 100px;
  --min-height: 100px;
  --max-width: auto;
  --height: auto;
  left: 0;
  right: 0;
  // GitHub Issue: https://github.com/EmmySoft-GmbH/emmysoft-frontend/issues/195
  top: calc(70px + var(--ion-safe-area-top));
  position: fixed;
  color: var(--ion-text-color, #000);
  z-index: 200003;
  padding-right: 30%;
  padding-left: 30%;
  overflow-y: auto;
  bottom: 20px;
  padding-bottom: 80px;

  ion-card {
    margin-top: 20px;
    border-top: none;
    border-radius: 10px;
  }

  ion-avatar {
    margin-right: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  ion-item {
    cursor: pointer;
  }
}

@media (min-width: 900px) {
  ion-searchbar {
    margin-right: 16px;
  }
}

@media (max-width: 899px) {
  .search-results {
    padding-left: 0;
    padding-right: 0;
  }
}

.search-icon {
  margin-left: 12px;
}

ion-searchbar:deep(.searchbar-input) {
  padding-left: 12px;
  text-align: left;
}

:deep(.searchbar-search-icon) {
  display: none;
  width: 0;
  height: 0;
}
</style>
