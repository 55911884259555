import { defineStore } from 'pinia'

import { Message } from '@/models/Message'

/**
 * A store which controls the MessageCenter and provides a convenient interface for adding and removing messages
 * that are presented to the user.
 */
export const useMessageStore = defineStore('messages', () => {
  // ===========
  // State
  // ===========

  const messages = ref<Message[]>([
    // Test message:
    // new Message('Welcome to the Message Center!', 'Success', 0)
  ])

  // ===========
  // Methods
  // ===========

  /**
   * Adds a new message to the message stack.
   * @param message The message to add.
   */
  function addMessage(message: Message) {
    messages.value.push(message)
  }

  /**
   * Removes a message from the message stack.
   * @param message The message to remove.
   */
  function removeMessage(message: Message) {
    const index = messages.value.indexOf(message)
    if (index > -1) {
      messages.value.splice(index, 1)
    }
  }

  /**
   * Remove message by its ID.
   * @param id The ID of the message to remove.
   */
  function removeMessageById(id: string) {
    const index = messages.value.findIndex(message => message.id === id)
    if (index > -1) {
      messages.value.splice(index, 1)
    }
  }

  /**
   * Clears all messages from the message stack.
   */
  function clearMessages() {
    messages.value = []
  }

  return {
    messages,
    addMessage,
    removeMessage,
    removeMessageById,
    clearMessages
  }
})
