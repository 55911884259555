<template>
  <div class="searchbar">
    <ion-searchbar
      placeholder="Search..."
      inputmode="search"
      type="search"
      enterkeyhint="search"
      autocomplete="off"
      autocorrect="off"
      @ion-change="handleSearch($event.target.value)"
    ></ion-searchbar>
    <ion-button @click="toggleActiveStatus">
      <ion-label v-if="isActive" class="active-label">
        {{ $t('emmyTalk.search.active') }}
      </ion-label>
      <ion-label v-else class="active-label">
        {{ $t('emmyTalk.search.inActive') }}
      </ion-label>
    </ion-button>
  </div>
</template>

<script>
import { IonSearchbar, IonButton, IonLabel } from '@ionic/vue'

import { useStore } from 'vuex'
import { ref } from 'vue'

export default {
  name: 'Search',
  components: { IonSearchbar, IonButton, IonLabel },
  setup() {
    const store = useStore()
    let results = []
    var isActive = ref(true)

    function toggleActiveStatus() {
      isActive.value = !isActive.value
      store.dispatch('TOGGLE_SHOW_INACTIVE', results)
    }

    function handleSearch(data) {
      results = store.state.chats.filter(message => {
        return message.text.toLowerCase().includes(data.toLowerCase())
      })
      store.dispatch('FILTER_MESSAGES', results)

      results = store.state.marketNews.filter(message => {
        return message.text.toLowerCase().includes(data.toLowerCase())
      })
      store.dispatch('FILTER_MARKET_NEWS', results)

      var candidates = []
      store.state.projects.map(project =>
        project.jobApplications.map(jobApplication => {
          candidates.push(jobApplication)
        })
      )

      results = candidates.filter(candidate => {
        return candidate.candidateName.toLowerCase().includes(data.toLowerCase())
      })
      store.dispatch('FILTER_CANDIDATES', results)

      results = store.state.projects.filter(project => {
        return project.name.toLowerCase().includes(data.toLowerCase())
      })
      store.dispatch('FILTER_PROJECTS', results)
    }

    return {
      isActive,
      toggleActiveStatus,
      handleSearch,
      results
    }
  }
}
</script>

<style scoped lang="scss">
.searchbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

ion-searchbar {
  height: 42px;
  margin: 10px;
  padding: 0;
  --border-radius: 4px;
  --placeholder-color: var(--font-secondary);
  --icon-color: #9a9eb3;
}

ion-button {
  height: 42px;
  margin: 10px 10px 10px 0;
  // --padding-top: 25px;
  // --padding-bottom: 25px;
  // --padding-start: 15px;
  // --padding-end: 15px;
  --background: #fff;
  ion-icon {
    color: #9a9eb3;
  }
}

.active-label {
  color: rgb(38, 38, 38);
  text-transform: none;
}
</style>
