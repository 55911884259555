<template>
  <div class="notifications__wrapper">
    <div class="header hydrated flex padding">
      <h4>{{ $t('header.account.title') }}</h4>
      <img src="/static/close.svg" class="pointer button" alt="close" @click="handleClose" />
    </div>

    <ion-item class="hydrated action pointer" @click="handleLanguageChange">
      <ion-label>{{ getNextLanguage }}</ion-label>
    </ion-item>

    <ion-item
      v-if="permissions && permissions.admin.enabled && baseUrls.admin"
      class="hydrated action admin-console pointer"
      @click="openUrl(baseUrls.admin)"
    >
      <ion-label>Admin Console</ion-label>
      <img src="/static/external-link.svg" class="external right-margin" alt="external link" />
    </ion-item>

    <ion-item lines="none" detail="false" class="hydrated action pointer" replace-true @click="handleLogout">
      <ion-label>{{ $t('login.logoutButton') }}</ion-label>
    </ion-item>
  </div>
</template>

<script>
import { IonItem, IonLabel } from '@ionic/vue'

import { useAuthStore } from '@/pinia/authStore'
import { useConfigurationStore } from '@/pinia/configStore'

export default {
  name: 'PopoverContent',
  components: { IonItem, IonLabel },
  setup() {
    const authStore = useAuthStore()
    const configStore = useConfigurationStore()

    return { authStore, configStore }
  },
  computed: {
    getNextLanguage: function () {
      if (this.$root.$i18n.locale === 'de') {
        return 'English'
      }
      return 'Deutsch'
    },
    permissions: function () {
      return this.authStore.getPermissions()
    },
    baseUrls: function () {
      return this.configStore.getEnv().proxy?.baseUrl ?? {}
    }
  },
  methods: {
    handleLogout: function () {
      this.authStore.signOut()
    },

    handleLanguageChange: function () {
      if (this.$root.$i18n.locale === 'de') {
        this.$root.$i18n.locale = 'en'
        this.configStore.setLocale('en')
      } else {
        this.$root.$i18n.locale = 'de'
        this.configStore.setLocale('de')
      }
    },

    openUrl: function (url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
ion-card {
  height: 170.79px;
  border: 1px solid #ededf0;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

ion-label {
  font-family: 'SF Pro Display';
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: var(--font-secondary);
}

.notifications__header {
  --padding-start: 0px;
  display: flex;
  justify-content: space-between;
  cursor: default;
}

.notifications__header-title {
  margin-top: 30px;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding-left: 30px;
}

.notifications__header-title:last-child {
  text-align: right;
  cursor: pointer;
}

.display-flex {
  display: flex;
}

.action {
  .item-native {
    padding-left: 0;
  }
  ion-label {
    padding-left: 16px;
    cursor: pointer;
  }
}

.header {
  padding: 42px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.flex {
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.padding {
  padding: 16px 32px;
}

.pointer {
  cursor: pointer;
}

.button {
  width: 20px;
  height: 20px;
}

.right-margin {
  margin-right: 16px;
}

.external {
  width: 20px;
  height: 20px;
  opacity: 0.3;
  transition: all 0.1s ease-in-out;
}

.admin-console:hover .external {
  opacity: 0.9;
}
</style>
