<template>
  {{ currentTimestamp(time) }}
</template>

<script>
export default {
  name: 'CurrentTimestamp',
  props: { time: String },
  methods: {
    currentTimestamp: function (time) {
      const monthNamelist = [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember'
      ]

      let date = new Date(time)
      return date.getDate() + '. ' + monthNamelist[date.getMonth()] + ' ' + date.getFullYear()
    }
  }
}
</script>

<style scoped lang="scss"></style>
