<template>
  <div class="boundry">
    <div class="tooltip-outer" :class="outerClasses">
      <div class="tooltip" :class="tooltipClasses">
        <p>{{ text }}</p>
      </div>
    </div>
    <div @mouseenter="isHovering = true" @mouseleave="isHovering = false">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    text: {
      type: String,
      default: 'Some Tooltip'
    },
    position: {
      type: String,
      default: 'top' // top, left, right, bottom, top-left, top-right, bottom-left, bottom-right
    },
    colorClass: {
      type: String,
      default: 'bg-black/primary'
    },
    textColorClass: {
      type: String,
      default: 'text-white/primary'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isHovering: false
    }
  },
  computed: {
    outerClasses() {
      return {
        hidden: !this.isHovering && !this.disabled,
        [this.position]: true
      }
    },
    tooltipClasses() {
      return {
        [this.colorClass]: true,
        [this.textColorClass]: true
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.boundry {
  position: relative;
  display: inline-block;
}
.tooltip-outer {
  pointer-events: none;
  position: absolute;
  display: block;
  transform: scale(1);
  opacity: 100%;
  transition: all ease-in-out 300ms;
  z-index: 100;
}
.tooltip {
  width: 100%;
  border-radius: 4px;
  font-size: 12px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  transition: all ease-in-out 300ms;
  color: #000;
  background-color: #fff;
}

p {
  white-space: nowrap;
  margin: 0;
  padding: 8px;
}

.hidden {
  opacity: 0;
  transform: scale(0);
}
.left {
  left: 0;
  top: 50%;
  transform: translate(-100%, -50%);
}
.right {
  right: 0;
  top: 50%;
  transform: translate(100%, -50%);
}
.top {
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}
.bottom {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
}

.top-left {
  top: 0;
  left: 0;
  transform: translate(-100%, -100%);
}

.top-right {
  top: 0;
  right: 0;
  transform: translate(100%, -100%);
}

.bottom-left {
  bottom: 0;
  left: 0;
  transform: translate(-100%, 100%);
}

.bottom-right {
  bottom: 0;
  right: 0;
  transform: translate(100%, 100%);
}
</style>
