import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useLoadingStore = defineStore('loading', () => {
  /**
   * Indicates whether the application is currently loading.
   */
  const isLoading = ref(false)

  /**
   * Starts the loading process.
   */
  function start() {
    isLoading.value = true
  }

  /**
   * Stops the loading process.
   */
  function stop() {
    isLoading.value = false
  }

  return {
    isLoading,
    start,
    stop
  }
})
