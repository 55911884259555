<template>
  <ion-card>
    <ion-card-header>
      <Avatar :size="40" />
      <div>
        <ion-card-title>
          <h4>{{ message.title }}</h4>
        </ion-card-title>
        <ion-card-subtitle>
          <CurrentTimestamp :time="message.createdDate" />
        </ion-card-subtitle>
      </div>
    </ion-card-header>
    <ion-card-content>
      <p>
        {{ message.text }}
      </p>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent } from '@ionic/vue'

import { useStore } from 'vuex'
import { computed } from 'vue'

import Avatar from '@/components/ui-elements/Avatar.vue'
import CurrentTimestamp from '@/components/ui-elements/CurrentTimestamp.vue'

export default {
  name: 'NewsMessageOverview',
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    Avatar,
    CurrentTimestamp
  },
  props: { message: Object, isOverlay: Boolean },
  setup(props) {
    const store = useStore()

    function openMessage(id) {
      if (props.isOverlay) {
        store.dispatch('OPEN_OVERLAY_MESSAGE', id, 'news')
      } else {
        store.dispatch('OPEN_ONE_MESSAGE', id, 'news')
      }
    }

    return {
      openMessage,
      active: computed(() => store.state.openMessage)
    }
  },
  methods: {
    isActive: function (id) {
      if (this.active === id) {
        return true
      }

      return false
    }
  }
}
</script>

<style scoped lang="scss">
ion-card {
  box-shadow: none;
  margin: 7px 4px;
  padding: 7px 16px;
  border-radius: 10px;
  width: 100%;
}

ion-card-header {
  margin: 0 0 3px;
  margin-top: 12px;
  padding: 0;
  display: flex;
  align-items: center;

  div {
    margin-left: 9px;
  }
}

ion-card-title {
  h4 {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--font-title);
  }
}

ion-card-subtitle {
  margin: 0;
  align-self: flex-start;
  font-size: 1rem;
  color: #889fb2;
}

ion-card-content {
  margin-top: 16px;
  margin-bottom: 20px;
  padding: 0;
  font-size: 1.2rem;

  ion-card-title {
    h4 {
      margin: 0;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: var(--font-title);
    }
  }

  p {
    margin-top: 1px;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}

.footer {
  border-top-color: #ededf0;
  border-top-style: solid;
  border-top-width: 1px;
  width: 100%;
}

.active {
  --background: var(--background-step-finished);

  ion-card-title h4,
  ion-card-content {
    color: var(--font-primary);
  }
  ion-card-subtitle {
    color: var(--primary);
  }
}
</style>
