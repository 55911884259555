// TODO: Replace with pinia

import { createStore } from 'vuex'
import apiConfig from '@/config/api'
import { useLoadingStore } from '@/pinia/loadingStore'
import { fetcher } from '../http'
import * as Sentry from '@sentry/vue'
import { useAuthStore } from '../pinia/authStore'

let projectInterval = null
let chatInterval = null

const state = {
  projects: [],

  chats: [],
  marketNews: [],
  notifications: [],

  projectColor: [
    '#02A79F',
    '#45D35C',
    '#74DED9',
    '#337CB7',
    '#E6A01F',
    '#FCEB46',
    '#02A79F',
    '#45D35C',
    '#74DED9',
    '#337CB7',
    '#E6A01F',
    '#FCEB46',
    '#02A79F'
  ],

  showInactive: false,
  filteredMessages: [],
  filteredMarketNews: [],
  filteredCandidates: [],
  filteredProjects: [],
  filterMessagesBy: '',
  overlayChats: [],
  overlayChatsContent: {},
  openMessage: {},
  overlaySidebar: false,
  activeSegment: 'all',
  emmytalkFullscreen: true, // Default fullscreen
  messageFullscreen: false,
  fullscreenWidth: 400,
  notificationIconActive: false
}

const getters = {
  getProject: state => id => {
    return state.projects.find(project => project.id === id)
  },

  getNotificationIconActive: state => {
    return state.notificationIconActive
  },

  getMessage: state => {
    if (state.openMessage.message_type === 'project') {
      return state.chats.find(chat => chat.projectId === state.openMessage.id && chat.candidateId === undefined)
    }

    if (state.openMessage.message_type === 'candidate') {
      return state.chats.find(chat => chat.candidateId === state.openMessage.id)
    }

    return ''
  },

  getOverlayMessages: state => {
    return state.overlayChats
  },

  getOverlayMessage: state => id => {
    if (id.message_type === 'project') {
      return state.chats.find(chat => chat.projectId === id.id && chat.candidateId === undefined)
    }
    return state.chats.find(chat => chat.candidateId === id.id)
  },

  getAllMessages: state => {
    return state.chats
  },

  getFilteredMessages: state => {
    let results = []
    let search = []

    if (state.filteredMessages.length === 0) {
      search = state.chats
    } else {
      search = state.filteredMessages
    }

    search.map(chat => {
      if (chat.candidateId == undefined) {
        var project = state.projects.find(project => project.id === chat.projectId)

        if (project != undefined) {
          if (state.showInactive === false && project.status != 'Cancelled') {
            results.push(chat)
          }
          if (state.showInactive === true && project.status === 'Cancelled') {
            results.push(chat)
          }
        }
      } else {
        var candidate = state.projects
          .find(project => project.id === chat.projectId)
          ?.jobApplications.find(jobApplication => jobApplication.id === chat.candidateId)

        if (candidate !== undefined) {
          if (state.showInactive === false && candidate.active === true) {
            results.push(chat)
          }
          if (state.showInactive === true && candidate.active === false) {
            results.push(chat)
          }
        }
      }
    })

    return results
  },

  getFilteredMarketNews: state => {
    if (state.filteredMarketNews.length === 0) {
      return state.marketNews
    }
    return state.filteredMarketNews
  },

  getFilteredCandidates: state => {
    if (state.filteredCandidates.length === 0) {
      var candidates = []
      state.projects.map(project =>
        project.jobApplications.map(jobApplication => {
          candidates.push(jobApplication)
        })
      )

      if (state.showInactive === false) {
        return candidates.filter(candidate => candidate.active === true)
      }
      return candidates.filter(candidate => candidate.active === false)
    }

    if (state.showInactive === false) {
      return state.filteredCandidates.filter(candidate => candidate.active === true)
    }
    return state.filteredCandidates.filter(candidate => candidate.active === false)
  },

  getFilteredProjects: state => {
    if (state.filteredProjects.length === 0) {
      return state.projects
    }

    return state.filteredProjects
  },

  getActiveSegment: state => {
    return state.activeSegment
  },

  getProfileMessages: state => {
    return state.chats.find(chat => chat.id === state.openMessage)
  },

  getFilteredProfileMessages: state => {
    if (state.filteredMessages.length === 0) {
      return state.chats
    }
    return state.filteredMessages
  },

  getCandidatesMessages: state => {
    return state.chats.find(chat => chat.id === state.openMessage)
  },

  getFilteredCandidatesMessages: state => {
    if (state.filteredMessages.length === 0) {
      return state.chats
    }
    return state.filteredMessages
  },

  getLastFiveCandidatesMessages: state => {
    return state.projects
  },

  getLastCandidatesInterviews: state => {
    return state.projects
  },

  getAllDocuments: state => id => {
    const project = state.projects.find(project => project.id === id)
    const result = project.jobApplications.reduce((r, obj) => r.concat(obj.documents), [])

    return result.filter(document => document.type != 'photo')
  },

  getCurrentPresentation: state => {
    return state.projects[0]
  },

  getCurrentCandidates: state => id => {
    return state.projects.find(project => project.id === id)
  },

  getCurrentContractNegotiations: state => {
    return state.projects
  },

  getProjectFromCandidate: state => route => {
    if (route.name === 'Candidate') {
      var candidates = state.projects.map(project =>
        project.jobApplications.find(jobApplication => jobApplication.id === route.params.id)
      )

      return candidates.find(candidate => candidate != undefined).projectId
    }
    return false
  },

  getCompleteCandidate: state => route => {
    var candidates = state.projects.map(project =>
      project.jobApplications.find(jobApplication => jobApplication.id === route.params.id)
    )

    return candidates.find(candidate => candidate != undefined)
  },

  getNotifications: state => {
    const unsorted = state.notifications
    const sorted = unsorted.sort(function (x, y) {
      if (new Date(x.created.at) > new Date(y.created.at)) {
        return -1
      }
      if (new Date(x.created.at) < new Date(y.created.at)) {
        return 1
      }
    })

    return sorted
  }
}

const actions = {
  GET_ALL_PROJECTS({ commit }) {
    const loadingStore = useLoadingStore()
    loadingStore.start()
    getProjects()

    async function getProjects() {
      const transaction = Sentry.startTransaction({
        op: 'getProjects',
        name: 'Get all projects'
      })

      fetcher(apiConfig.resources.projects)
        .then(async response => {
          commit('SET_PROJECTS', { projects: await response.json() })
          loadingStore.stop()
          transaction.finish()
        })
        .catch(error => {
          console.error(error)
          loadingStore.stop()
          // Log the error to Sentry
          Sentry.captureException(error)
          // Finish the transaction
          transaction.finish()
        })
    }

    if (projectInterval) {
      clearInterval(projectInterval)
    }

    projectInterval = setInterval(getProjects, 1 * 60 * 1000) // 1 minutes
  },

  GET_ALL_CHATS({ commit }) {
    getChats()

    function getChats() {
      const transaction = Sentry.startTransaction({
        op: 'getChatMessages',
        name: 'Get all chat messages'
      })

      fetcher(apiConfig.resources.chats)
        .then(async response => {
          commit('SET_CHATS', { chats: await response.json() })
          transaction.finish()
        })
        .catch(error => {
          console.error(error)
          // Log the error to Sentry
          Sentry.captureException(error)
          // Finish the transaction
          transaction.finish()
        })
    }

    if (chatInterval) {
      clearInterval(chatInterval)
    }

    chatInterval = setInterval(getChats, 1 * 60 * 1000) // 1 minute
  },

  GET_ALL_NOTIFICATIONS({ commit }) {
    const transaction = Sentry.startTransaction({
      op: 'getNotifications',
      name: 'Get all notifications'
    })

    fetcher(apiConfig.resources.notifications)
      .then(async response => {
        commit('SET_NOTIFICATIONS', { notifications: await response.json() })
        transaction.finish()
      })
      .catch(error => {
        console.error(error)
        // Log the error to Sentry
        Sentry.captureException(error)
        // Finish the transaction
        transaction.finish()
      })
  },

  RESET({ commit }) {
    commit('SET_PROJECTS', { projects: [] })
    commit('SET_CHATS', { chats: [] })
    commit('SET_MARKET_NEWS', { chats: [] })
    commit('SET_NOTIFICATIONS', { notifications: [] })

    clearInterval(projectInterval)
    clearInterval(chatInterval)
  },

  FILTER_MESSAGES({ commit }, payload) {
    commit('SET_FILTERED_MESSAGES', payload)
  },

  FILTER_MARKET_NEWS({ commit }, payload) {
    commit('SET_FILTERED_MARKET_NEWS', payload)
  },

  FILTER_CANDIDATES({ commit }, payload) {
    commit('SET_FILTERED_CANDIDATES', payload)
  },

  FILTER_PROJECTS({ commit }, payload) {
    commit('SET_FILTERED_PROJECTS', payload)
  },

  SEND_PROJECT_MESSAGE({ commit }, payload) {
    commit('SET_PROJECT_MESSAGE', payload)
  },

  SEND_CANDIDATE_MESSAGE({ commit }, payload) {
    commit('SET_CANDIDATE_MESSAGE', payload)
  },

  DELETE_MESSAGE({ commit }, payload) {
    commit('PERFORM_DELETE_MESSAGE', payload)
  },

  MARK_NOTIFICATION_AS_READ({ commit }, payload) {
    commit('SET_NOTIFICATION_AS_READ', payload)
  },

  MARK_ALL_NOTIFICATION_AS_READ({ commit }) {
    commit('SET_ALL_NOTIFICATION_AS_READ')
  },

  OPEN_ONE_MESSAGE({ commit }, payload) {
    commit('OPEN_MESSAGE', payload)
  },

  OPEN_OVERLAY_MESSAGE({ commit }, payload) {
    commit('SET_OVERLAY_MESSAGE', payload)
  },

  WRITE_OVERLAY_MESSAGE_CONTENT({ commit }, id, content) {
    commit('SET_OVERLAY_MESSAGE_CONTENT', id, content)
  },

  CLOSE_OVERLAY_MESSAGE({ commit }, payload) {
    commit('CLOSE_OVERLAY_MESSAGE', payload)
  },

  CLOSE_ALL_MESSAGES({ commit }) {
    commit('CLOSE_OVERLAY_MESSAGES')
  },

  OPEN_SIDEBAR({ commit }) {
    commit('OPEN_OVERLAY_SIDEBAR')
  },

  CLOSE_SIDEBAR({ commit }) {
    commit('CLOSE_OVERLAY_SIDEBAR')
  },

  TOGGLE_SIDEBAR({ commit }) {
    commit('SET_SIDEBAR')
  },

  CLOSE_ONE_MESSAGE({ commit }) {
    commit('CLOSE_MESSAGE')
  },

  SHOW_EMMTYTALK_PROJECT_TICKETS({ commit }, payload) {
    commit('SET_EMMTYTALK_PROJECT_TICKETS', payload)
  },

  CHANGE_ACTIVE_SEGMENT({ commit }, payload) {
    commit('SET_ACTIVE_SEGMENT', payload)
  },

  TOGGLE_SHOW_INACTIVE({ commit }) {
    commit('SET_SHOW_INACTIVE')
  },

  TOGGLE_MESSAGE_FULLSCREEN({ commit }, payload) {
    commit('SET_MESSAGE_FULLSCREEN', payload)
  },

  TOGGLE_FULLSCREEN({ commit }) {
    commit('SET_FULLSCREEN')
  }
}

const mutations = {
  SET_PROJECTS(state, { projects }) {
    projects.map((project, index) => (project.color = state.projectColor[index % state.projectColor.length]))

    state.projects = projects
  },

  SET_CHATS(state, { chats }) {
    state.chats = chats
  },

  SET_MARKET_NEWS(state, { chats }) {
    state.marketNews = chats
  },

  SET_PROJECT_MESSAGE(state, payload) {
    var chat = state.chats.find(chat => chat.projectId === payload.projectId && chat.candidateId == undefined)

    const authStore = useAuthStore()

    fetcher(apiConfig.resources.chats + '/' + chat.id, {
      method: 'POST',
      body: JSON.stringify({ text: payload.text })
    })
      .then(async response => {
        const data = await response.json()

        var comment = {
          createdDate: data.createdDate,
          text: payload.text,
          createdBy: {
            cognitoUserId: authStore.getUsername(),
            firstName: authStore.getGivenName(),
            foto: 'https://dfind.com/wp-content/uploads/2018/11/CR-Zitat-300x297.jpg',
            lastName: authStore.getFamilyName()
          }
        }

        chat.comments.push(comment)
      })
      .catch(error => {
        console.error(error)
      })
  },

  SET_CANDIDATE_MESSAGE(state, payload) {
    var chat = state.chats.find(chat => chat.candidateId === payload.messageId.id)

    fetcher(apiConfig.resources.chats + '/' + chat.id, {
      method: 'POST',
      body: JSON.stringify({ text: payload.text })
    })
      .then(async response => {
        const data = await response.json()

        const authStore = useAuthStore()

        var comment = {
          createdDate: data.timestamp,
          text: payload.text,
          createdBy: {
            cognitoUserId: authStore.getUsername(),
            firstName: authStore.getGivenName(),
            foto: 'https://dfind.com/wp-content/uploads/2018/11/CR-Zitat-300x297.jpg',
            lastName: authStore.getFamilyName()
          }
        }

        chat.comments.push(comment)
      })
      .catch(error => {
        console.error(error)
      })
  },

  SET_NOTIFICATION_AS_READ(state, payload) {
    fetcher(apiConfig.resources.notifications + '/' + payload + '/read')
      .then(() => {
        state.notificationIconActive = false
        state.notifications.map(notification => {
          if (notification.read === undefined) {
            state.notificationIconActive = true
          }
        })
      })
      .catch(error => {
        console.error(error)
      })
  },

  SET_ALL_NOTIFICATION_AS_READ() {
    fetcher(apiConfig.resources.notifications + '/read')
      .then(response => {
        state.notificationIconActive = false
      })
      .catch(error => {
        console.error(error)
      })
  },

  SET_NOTIFICATIONS(state, { notifications }) {
    notifications.map(notification => {
      if (notification.read === undefined) {
        state.notificationIconActive = true
      }
    })
    state.notifications = notifications
  },

  PERFORM_DELETE_MESSAGE(state, commentId) {
    fetcher(apiConfig.resources.chats + '/comment/' + commentId, {
      method: 'DELETE'
    })
      .then(() => {
        console.log(`Comment ${commentId} deleted successfully`)

        state.chats = state.chats.map(chat => {
          chat.comments = chat.comments.filter(comment => comment.id !== commentId)
          return chat
        })
      })
      .catch(error => {
        console.log('Unable to delete comment ' + commentId)
        throw error
      })
  },

  SET_FILTERED_MESSAGES(state, payload) {
    state.filteredMessages = payload
  },

  SET_FILTERED_MARKET_NEWS(state, payload) {
    state.filteredMarketNews = payload
  },

  SET_FILTERED_CANDIDATES(state, payload) {
    state.filteredCandidates = payload
  },

  SET_FILTERED_PROJECTS(state, payload) {
    state.filteredProjects = payload
  },

  OPEN_MESSAGE(state, payload) {
    state.openMessage = payload
  },

  OPEN_MULTIPLE_MESSAGES(state, payload) {
    state.overlayChats.push({ id: payload })
  },

  SET_OVERLAY_MESSAGE(state, payload) {
    if (!state.overlayChats.find(chat => chat.id === payload.id && chat.message_type == payload.message_type)) {
      state.overlayChats = [...state.overlayChats, payload]
    }
  },

  SET_OVERLAY_MESSAGE_CONTENT(state, id, content) {
    state.overlayChats = { id: id, content: content }
  },

  CLOSE_OVERLAY_MESSAGE(state, payload) {
    state.overlayChats.splice(
      state.overlayChats.findIndex(chat => chat.id === payload),
      1
    )
  },

  CLOSE_OVERLAY_MESSAGES(state) {
    state.overlayChats = []
  },

  OPEN_OVERLAY_SIDEBAR(state) {
    state.overlaySidebar = true
  },

  CLOSE_OVERLAY_SIDEBAR(state) {
    state.overlaySidebar = false
  },

  SET_SIDEBAR(state) {
    state.overlaySidebar = !state.overlaySidebar
  },

  CLOSE_MESSAGE(state) {
    state.overlayChats = []
  },

  SET_EMMTYTALK_PROJECT_TICKETS(state, payload) {
    state.overlaySidebar = true
    state.filterMessagesBy = payload
  },

  SET_ACTIVE_SEGMENT(state, payload) {
    state.activeSegment = payload
  },

  SET_SHOW_INACTIVE(state) {
    state.showInactive = !state.showInactive
  },

  SET_MESSAGE_FULLSCREEN(state, payload) {
    state.messageFullscreen = !state.messageFullscreen

    if (state.messageFullscreen === true) {
      state.fullscreenWidth = payload
    } else {
      state.fullscreenWidth = 400
    }
  },

  SET_FULLSCREEN_WIDTH(state, payload) {
    state.fullscreenWidth = payload
  },

  SET_FULLSCREEN(state) {
    state.emmytalkFullscreen = !state.emmytalkFullscreen
  }
}

export default createStore({
  state,
  getters,
  actions,
  mutations
})
