<template>
  <div v-if="!alignRight" class="chat">
    <div class="chat__info">
      <Avatar :photo="comment.createdBy.photoURL" :size="36" />
      <div class="chat__user">
        <p class="chat__username">{{ comment.createdBy.firstName }} {{ comment.createdBy.lastName }}</p>
        <p class="chat__date">
          {{ $d(comment.createdDate, 'short') }}
        </p>
      </div>
    </div>
    <p class="chat__message">
      {{ comment.text }}
    </p>
  </div>

  <div v-else class="chat">
    <div class="chat__info right">
      <Avatar :photo="comment.createdBy.photoURL" :size="36" />
      <div class="chat__user">
        <p class="chat__username">{{ comment.createdBy.firstName }} {{ comment.createdBy.lastName }}</p>
        <p class="chat__date">
          {{ $d(comment.createdDate, 'short') }}
        </p>
      </div>
    </div>
    <ion-item-sliding @mouseover="hover = true" @mouseleave="hover = false">
      <div class="chat__info right">
        <ion-item lines="none" class="chat__message right">
          {{ comment.text }}
        </ion-item>
        <div v-if="hover" class="chat__delete-message right" @click="deleteMessage(comment.id)">
          <svg width="24" height="24" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.75">
              <path
                d="M50.7 8.6002H41V5.0002C41 2.9002 39.3 1.2002 37.2 1.2002H26.8C24.7 1.3002 23 2.9002 23 5.0002V8.6002H13.3C11.2 8.6002 9.5 10.3002 9.5 12.4002V19.7002C9.5 20.7002 10.3 21.5002 11.3 21.5002H12.8V55.4002C12.8 59.5002 16.2 62.9002 20.3 62.9002H43.8C47.9 62.9002 51.3 59.5002 51.3 55.4002V21.3002H52.8C53.8 21.3002 54.6 20.5002 54.6 19.5002V12.2002C54.4 10.2002 52.8 8.6002 50.7 8.6002ZM26.5 5.0002C26.5 4.9002 26.6 4.7002 26.8 4.7002H37.2C37.3 4.7002 37.5 4.8002 37.5 5.0002V8.6002H26.5V5.0002ZM13.1 12.3002C13.1 12.2002 13.2 12.0002 13.4 12.0002H24.9H39.3H50.8C50.9 12.0002 51.1 12.1002 51.1 12.3002V17.8002H13.1V12.3002ZM47.7 55.3002C47.7 57.5002 45.9 59.3002 43.7 59.3002H20.3C18.1 59.3002 16.3 57.5002 16.3 55.3002V21.3002H47.8V55.3002H47.7Z"
                fill="black"
              />
              <path
                d="M32.0002 48.3001C33.0002 48.3001 33.8002 47.5001 33.8002 46.5001V33.4001C33.8002 32.4001 33.0002 31.6001 32.0002 31.6001C31.0002 31.6001 30.2002 32.4001 30.2002 33.4001V46.6001C30.3002 47.6001 31.0002 48.3001 32.0002 48.3001Z"
                fill="black"
              />
              <path
                d="M40.4001 48.3001C41.4001 48.3001 42.2001 47.5001 42.2001 46.5001V33.4001C42.2001 32.4001 41.4001 31.6001 40.4001 31.6001C39.4001 31.6001 38.6001 32.4001 38.6001 33.4001V46.6001C38.7001 47.6001 39.5001 48.3001 40.4001 48.3001Z"
                fill="black"
              />
              <path
                d="M23.5998 48.3001C24.5998 48.3001 25.3998 47.5001 25.3998 46.5001V33.4001C25.3998 32.4001 24.5998 31.6001 23.5998 31.6001C22.5998 31.6001 21.7998 32.4001 21.7998 33.4001V46.6001C21.7998 47.6001 22.5998 48.3001 23.5998 48.3001Z"
                fill="black"
              />
            </g>
          </svg>
        </div>
      </div>

      <ion-item-options>
        <ion-item-option @click="deleteMessage(comment.id)">
          <svg width="32" height="26" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.75">
              <path
                d="M50.7 8.6002H41V5.0002C41 2.9002 39.3 1.2002 37.2 1.2002H26.8C24.7 1.3002 23 2.9002 23 5.0002V8.6002H13.3C11.2 8.6002 9.5 10.3002 9.5 12.4002V19.7002C9.5 20.7002 10.3 21.5002 11.3 21.5002H12.8V55.4002C12.8 59.5002 16.2 62.9002 20.3 62.9002H43.8C47.9 62.9002 51.3 59.5002 51.3 55.4002V21.3002H52.8C53.8 21.3002 54.6 20.5002 54.6 19.5002V12.2002C54.4 10.2002 52.8 8.6002 50.7 8.6002ZM26.5 5.0002C26.5 4.9002 26.6 4.7002 26.8 4.7002H37.2C37.3 4.7002 37.5 4.8002 37.5 5.0002V8.6002H26.5V5.0002ZM13.1 12.3002C13.1 12.2002 13.2 12.0002 13.4 12.0002H24.9H39.3H50.8C50.9 12.0002 51.1 12.1002 51.1 12.3002V17.8002H13.1V12.3002ZM47.7 55.3002C47.7 57.5002 45.9 59.3002 43.7 59.3002H20.3C18.1 59.3002 16.3 57.5002 16.3 55.3002V21.3002H47.8V55.3002H47.7Z"
                fill="black"
              />
              <path
                d="M32.0002 48.3001C33.0002 48.3001 33.8002 47.5001 33.8002 46.5001V33.4001C33.8002 32.4001 33.0002 31.6001 32.0002 31.6001C31.0002 31.6001 30.2002 32.4001 30.2002 33.4001V46.6001C30.3002 47.6001 31.0002 48.3001 32.0002 48.3001Z"
                fill="black"
              />
              <path
                d="M40.4001 48.3001C41.4001 48.3001 42.2001 47.5001 42.2001 46.5001V33.4001C42.2001 32.4001 41.4001 31.6001 40.4001 31.6001C39.4001 31.6001 38.6001 32.4001 38.6001 33.4001V46.6001C38.7001 47.6001 39.5001 48.3001 40.4001 48.3001Z"
                fill="black"
              />
              <path
                d="M23.5998 48.3001C24.5998 48.3001 25.3998 47.5001 25.3998 46.5001V33.4001C25.3998 32.4001 24.5998 31.6001 23.5998 31.6001C22.5998 31.6001 21.7998 32.4001 21.7998 33.4001V46.6001C21.7998 47.6001 22.5998 48.3001 23.5998 48.3001Z"
                fill="black"
              />
            </g>
          </svg>
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </div>
</template>

<script>
import { IonItem, IonItemSliding, IonItemOptions, IonItemOption } from '@ionic/vue'

import Avatar from '@/components/ui-elements/Avatar.vue'

import { useStore } from 'vuex'

export default {
  name: 'Comment',
  components: {
    IonItem,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    Avatar
  },
  props: {
    comment: Object,
    alignRight: Boolean
  },
  setup() {
    const store = useStore()

    function deleteMessage(id) {
      store.dispatch('DELETE_MESSAGE', id)
    }

    return {
      deleteMessage
    }
  },
  data() {
    return {
      hover: false
    }
  }
}
</script>

<style scoped lang="scss">
.chat {
  display: block;
}
.chat__info {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: flex-start;
}

.chat__info.right {
  justify-content: flex-end;

  .chat__user {
    padding-right: 10px;
  }
}

.chat__user {
  padding-left: 10px;
}

.chat__username {
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;

  color: var(--font-title);
}

.chat__date {
  font-size: 12px;
  line-height: 15px;

  color: var(--font-secondary);
}
.chat__message {
  width: fit-content;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  background: var(--background-secondary);
  border-radius: 0px 10px 10px 10px;
  margin-bottom: 26px;

  color: var(--font-primary);
  font-size: 14px;
  line-height: 140%;
  word-break: break-word;
}

.chat__message.right {
  padding: 0px;
  background: #d5faff !important;
  border-radius: 10px 0px 10px 10px;
  word-break: break-word;
  align-content: flex-end;
  --background: #d5faff;
  --border: 0;
}

.chat__delete-message {
  right: 0;
  bottom: 0;
  padding-top: 6px;
  padding-bottom: 3px;
  padding-left: 7px;
  padding-right: 7px;
  text-align: center;
  align-content: flex-end;
  margin-bottom: 25px;
  margin-left: 10px;
  border-radius: 10px;
  background: #fdea5d !important;
  opacity: 1;
  cursor: pointer;
}

@media (max-width: 899px) {
  .chat__delete-message {
    display: none !important;
  }
}

ion-item-option {
  --background: #fdea5d;
  border-radius: 10px;
  margin-left: 10px;
  max-height: 50px;
}
</style>
