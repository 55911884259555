<template>
  <div class="chat-button">
    <ChatIcon />
  </div>
</template>

<script>
import ChatIcon from '@/assets/chat.svg'

export default {
  name: 'StartChatButton',
  components: {
    ChatIcon
  },
  props: {}
}
</script>

<style scoped lang="scss">
.chat-button {
  position: absolute;
  top: 5px;
}

svg {
  path:first-child {
    fill: var(--circle-background-emmytalk);
  }

  path:not(:first-child) {
    fill: var(--circle-color-emmytalk);
  }
}
</style>
