<template>
  <ion-card :class="{ active: isActive(message.id), inactiveOverlay: showInactive }" @click="openCandidate(message.id)">
    <status-bar class="candidate-status" :display="message.stepActions" style="padding: 12px 0" />
    <ion-card-header>
      <div class="display-flex justify-content-space-between">
        <Avatar :documents="message.documents" :size="64" />
        <StartChatButton button="true" class="chat-button" @click.stop="openMessage(message.id)" />
      </div>
      <div>
        <ion-card-title class="wrap-text">
          {{ message.candidateName }}
        </ion-card-title>
        <ion-card-subtitle class="wrap-text">
          {{ message.vCard }}
        </ion-card-subtitle>
      </div>
    </ion-card-header>
  </ion-card>
</template>

<script>
import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle } from '@ionic/vue'

import { useStore } from 'vuex'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import Avatar from '@/components/ui-elements/Avatar.vue'
import StartChatButton from '@/components/ui-elements/StartChatButton.vue'

import StatusBar from '@/components/ui-elements/StatusBar.vue'

export default {
  name: 'ProjectCandidateMessageOverview',
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    Avatar,
    StartChatButton,
    StatusBar
  },
  props: { message: Object, isOverlay: Boolean },
  setup(props) {
    const store = useStore()
    const router = useRouter()

    function openMessage(id) {
      var payload = { id: id, message_type: 'candidate' }

      if (props.isOverlay) {
        store.dispatch('OPEN_OVERLAY_MESSAGE', payload)
      } else {
        store.dispatch('OPEN_ONE_MESSAGE', payload)
        router.push('/emmytalk')
      }
    }

    return {
      openMessage,
      active: computed(() => store.state.openMessage),
      projects: computed(() => store.state.projects),
      showInactive: computed(() => store.state.showInactive)
    }
  },
  methods: {
    findProjectById: function (projectId) {
      var project = this.$store.getters.getProject(projectId)
      if (project != undefined) {
        return project.name
      }

      return ''
    },

    isActive: function (id) {
      if (this.active === id) {
        return true
      }

      return false
    },

    toggleMobileSidebar: function () {
      this.$store.dispatch('TOGGLE_SIDEBAR')

      if (this.$route.fullPath.includes('candidates')) {
        this.$store.dispatch('CHANGE_ACTIVE_SEGMENT', 'candidates')
      }

      if (this.$route.fullPath.includes('projects')) {
        this.$store.dispatch('CHANGE_ACTIVE_SEGMENT', 'project')
      }
    },

    openCandidate: function (id) {
      this.$router.push('/candidates/' + id)

      if (window.innerWidth <= 899) {
        // Toggle sidebar on mobile
        this.toggleMobileSidebar()
      }
    }
  }
}
</script>

<style scoped lang="scss">
ion-card {
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 7px 0;
  border-radius: 10px;
  width: 100%;
  margin-left: 10px;
  padding-top: 30px;
  padding-bottom: 35px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

ion-card-header {
  height: 45px;
  padding: 0;
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  margin-top: 30px;
  margin-bottom: 20px;

  div {
    margin-left: 20px;
  }
}

ion-card-title {
  h4 {
    margin: 0;
    font-size: 1.3rem;
    font-weight: 600;
    color: $primary;
  }

  p {
    font-size: 14px;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  div {
    margin-left: 20px;
  }
}

ion-card-title {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--font-title);
}

ion-card-subtitle {
  margin: 0;
  align-self: flex-start;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
}

ion-card-content {
  padding: 0;
  font-size: 1.2rem;
  padding-bottom: 20px;

  p {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}

.active {
  --background: var(--background-step-finished);

  ion-card-title h4,
  ion-card-content {
    color: var(--font-primary);
  }
  ion-card-subtitle {
    color: var(--primary);
  }
}

ion-button {
  margin-left: auto;
  --background: transparent;
  --border-color: #25a8a9;
  --border-radius: 50px;
  --border-width: 2px;
  --border-style: solid;
  --color: #25a8a9;

  text-transform: none;
  font-size: 1.3rem;
  font-weight: 600;

  ion-icon {
    margin-right: 10px;
  }
}

.candidate-status > current-step {
  padding-left: 0px !important;
}

ion-item > .candidate-status__steps--current {
  padding-left: 10px !important;
  padding-right: 30px !important;
}

.candidate-status > ion-item {
  padding-left: 10px !important;
  padding-right: 30px !important;
}

.candidate-status__steps--current {
  --padding-left: 10px !important;
  --padding-right: 30px !important;
}

body
  > div.overlay
  > ion-card
  > div
  > ion-list:nth-child(3)
  > ion-item:nth-child(1)
  > ion-card
  > ion-card-content
  > ion-card:nth-child(1)
  > ion-chip
  > ion-item.candidate-status__steps--current.item.md.in-list.ion-focusable.hydrated {
  --padding-left: 10px !important;
  --padding-right: 30px !important;
  padding-left: 10px !important;
  padding-right: 30px !important;
}

.wrap-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 200px;
}

.display-flex {
  display: flex;
}

.justify-content-space-between {
  justify-content: space-between;
}

.chat-button {
  top: 5px;
  left: 35px;
}
</style>
