<template>
  <div v-if="count.length > maxCount" class="abbreviation-icon">+{{ avatarsLeft(count.length) }}</div>
</template>

<script>
export default {
  name: 'AvatarCount',
  props: { count: Array, maxCount: Number },
  methods: {
    findProjectById: function (projectId) {
      var project = this.$store.getters.getProject(projectId)
      if (project != undefined) {
        return project.name
      }

      return ''
    },

    avatarsLeft: function (count) {
      return count - this.maxCount
    }
  }
}
</script>

<style scoped lang="scss">
.abbreviation-icon {
  height: 34px;
  width: 34px;
  margin-right: 10px;
  background: $primary;
  padding: 15px;
  border-radius: 100px;
  color: var(--font-white);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 20px rgb(0 0 0 / 10%);
}
</style>
