<template>
  <div class="message__new">
    <ion-textarea
      v-model="response"
      type="text"
      :placeholder="$t('emmyTalk.send.placeholder')"
      rows="3"
      enterkeyhint="send"
      @keydown.enter.exact.prevent="isSubmit()"
    />
    <ion-button type="submit" :disabled="isSending || response == ''" @click="isSubmit()">
      <ion-spinner v-if="isSending"></ion-spinner>
      <span v-if="!isSending">{{ $t('emmyTalk.send.sendButton') }}</span>
    </ion-button>
  </div>
</template>

<script>
import { IonTextarea, IonButton, IonSpinner } from '@ionic/vue'

import { attachOutline, imagesOutline } from 'ionicons/icons'

import { ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'SendArea',
  components: {
    IonTextarea,
    IonButton,
    IonSpinner
  },
  props: { messageId: Object, projectId: String },
  setup(props) {
    const store = useStore()
    const isSending = ref(false)
    const isActive = ref(true)
    var response = ref('')

    function isSubmit() {
      // Dismiss sending due to some other message being sent
      // or the message being empty
      if (isSending.value || response.value == '') {
        return
      }

      isSending.value = true
      isActive.value = false

      var payload = {}
      payload.text = response.value
      payload.projectId = props.projectId
      payload.messageId = props.messageId

      if (props.messageId.message_type === 'project') {
        store.dispatch('SEND_PROJECT_MESSAGE', payload)
      }

      if (props.messageId.message_type === 'candidate') {
        store.dispatch('SEND_CANDIDATE_MESSAGE', payload)
      }

      isSending.value = false
      isActive.value = true
      response.value = ''
    }

    return {
      attachOutline,
      imagesOutline,
      isSending,
      isActive,
      response,
      isSubmit
    }
  }
}
</script>

<style scoped lang="scss">
.message__new {
  margin-bottom: 0px;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: space-between;
  border-top: 1px solid #ededf0;
}

ion-textarea {
  font-size: 14px;
  line-height: 15px;
  background-color: #f5f6fb;
  color: var(--font-secondary);
  border-radius: 10px;
}
ion-button {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;

  color: var(--primary);
  width: 102px;

  --background: #fceb46;
  border-radius: 4px;
}
</style>
