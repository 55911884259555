export default {
  resources: {
    projects: 'projects',
    candidates: 'candidates',
    chats: 'tickets',
    news: 'news',
    documents: 'documents',
    notifications: 'notifications',
    deviceId: 'notifications/deviceId'
  },
  apiKey: undefined
}
