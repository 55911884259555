<template>
  <ion-card
    button="true"
    :class="{ inactiveOverlay: showInactive }"
    @click="openMessage(message.projectId, message.candidateId)"
  >
    <ion-card-header>
      <Avatar
        v-if="message.candidateId !== undefined"
        :documents="findCandidatePhotoById(message.projectId, message.candidateId)"
        :size="64"
      />
      <AvatarProject v-else class="profile__avatar" :project-id="message.projectId" :size="64" />

      <div v-if="message.candidateId !== undefined">
        <ion-card-title>
          <span>Thema: </span>
          {{ findCandidateNameById(message.projectId, message.candidateId) }}
        </ion-card-title>

        <ion-card-subtitle class="v-card">
          {{ findCandidateById(message.projectId, message.candidateId) }}
        </ion-card-subtitle>
        <ion-card-subtitle class="project">
          {{ $t('emmyTalk.project') }}: {{ findProjectById(message.projectId) }}
        </ion-card-subtitle>
      </div>

      <div v-else>
        <ion-card-title>
          <span>Thema: </span>
          {{ findProjectById(message.projectId) }}
        </ion-card-title>
      </div>
      <ion-card-subtitle v-if="message.comments !== undefined && message.comments.length > 0" class="update-label">
        {{ $d(message.comments[message.comments.length - 1].timestamp, 'short') }}
      </ion-card-subtitle>
      <ion-card-subtitle v-else>
        {{ $d(message.createdDate, 'short') }}
      </ion-card-subtitle>
    </ion-card-header>
    <ion-card-content v-if="message.comments !== undefined && message.comments.length > 0">
      <ion-card-subtitle
        v-if="message.comments[message.comments.length - 1].createdBy.firstName !== null"
        class="project createdBy"
      >
        {{ message.comments[message.comments.length - 1].createdBy.firstName }}
        {{ message.comments[message.comments.length - 1].createdBy.lastName }}:
      </ion-card-subtitle>
      <ion-card-subtitle class="v-card message">
        {{ message.comments[message.comments.length - 1].text }}
      </ion-card-subtitle>
    </ion-card-content>
    <ion-card-content v-else>
      <ion-card-subtitle class="project createdBy"> {{ getCreatedByName(message) }}: </ion-card-subtitle>
      <ion-card-subtitle class="v-card message">
        {{ message.text }}
      </ion-card-subtitle>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent } from '@ionic/vue'

import { useStore } from 'vuex'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import Avatar from '@/components/ui-elements/Avatar.vue'
import AvatarProject from '@/components/ui-elements/AvatarProject.vue'

export default {
  name: 'MessageOverview',
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    Avatar,
    AvatarProject
  },
  props: { message: Object, isOverlay: Boolean },
  setup(props) {
    const store = useStore()
    const router = useRouter()

    function openMessage(projectId, candidateId) {
      var payload = {}

      if (candidateId != undefined && candidateId.length > 0) {
        payload = { id: candidateId, message_type: 'candidate' }
      } else {
        payload = { id: projectId, message_type: 'project' }
      }

      if (props.isOverlay) {
        store.dispatch('OPEN_OVERLAY_MESSAGE', payload)
      } else {
        store.dispatch('OPEN_ONE_MESSAGE', payload)
        router.push('/emmytalk')
      }
    }

    return {
      openMessage,
      alreadyOpened: computed(() => store.state.openMessage),
      active: computed(() => store.state.openMessage),
      showInactive: computed(() => store.state.showInactive)
    }
  },
  mounted() {
    if (this.isOverlay === false && this.alreadyOpened.id === undefined && this.$route.name === 'Emmytalk') {
      this.openMessage(this.message.projectId, this.message.candidateId)
    }
  },
  methods: {
    isActive: function (id) {
      if (this.active === id) {
        return true
      }

      return false
    },

    findProjectById: function (projectId) {
      var project = this.$store.getters.getProject(projectId)
      if (project != undefined) {
        return project.name
      }

      return ''
    },

    findCandidateById: function (projectId, candidateId) {
      if (candidateId !== undefined) {
        var candidate = this.$store.getters
          .getProject(projectId)
          ?.jobApplications.find(jobApplication => jobApplication.id === candidateId)

        if (candidate !== undefined) {
          return candidate.vCard
        }
      }
      return ''
    },

    findCandidatePhotoById: function (projectId, candidateId) {
      if (candidateId !== undefined) {
        var candidate = this.$store.getters
          .getProject(projectId)
          ?.jobApplications?.find(jobApplication => jobApplication.id === candidateId)
        if (candidate !== undefined) {
          return candidate.documents
        }
      }
      return ''
    },

    findCandidateNameById: function (projectId, candidateId) {
      if (candidateId !== undefined) {
        var candidate = this.$store.getters
          .getProject(projectId)
          ?.jobApplications?.find(jobApplication => jobApplication.id === candidateId)

        if (candidate !== undefined) {
          return candidate.candidateName
        }
      }
      return ''
    },

    getCreatedByName: function (message) {
      if (message.createdBy != undefined) {
        if (message.createdBy.firstName != undefined && message.createdBy.lastName != undefined) {
          return message.createdBy.firstName + ' ' + message.createdBy.lastName
        }
      }

      return 'Unbekannt'
    }
  }
}
</script>

<style scoped lang="scss">
ion-card {
  box-shadow: none;
  margin: 7px 4px;
  padding: 7px 14px;
  border-radius: 10px;
  width: 100%;
}

ion-card-header {
  margin: 0 0 3px;
  margin-top: 14px;
  padding: 0;
  display: flex;
  align-items: center;

  div {
    margin-left: 10px;

    ion-card-subtitle {
      margin-left: none;
    }

    ion-card-title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 180px;
    }
  }

  ion-card-subtitle {
    margin-left: auto;
  }
}

ion-card-title {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--font-title);
}

ion-card-subtitle {
  margin: 0;
  align-self: flex-start;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
}

ion-card-content {
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 0;
  font-size: 1.2rem;

  p {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}

.active {
  --background: var(--background-step-finished);

  ion-card-title,
  ion-card-content {
    color: var(--font-primary);
  }
  ion-card-subtitle {
    color: var(--primary);
  }
}

.project {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: auto;
}

.v-card {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: var(--font-secondary);
  margin-top: 3px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 180px;
}

.project {
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  color: var(--primary);
  margin-top: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 180px;
}

.display-flex {
  display: flex;
  align-items: center;
  align-self: center;
}

.createdBy {
  margin-top: 0px;
}

.message {
  margin-top: 4px;
  margin-left: px;
  width: 300px;
}

.inactiveOverlay {
  opacity: 0.75;
}

.profile__avatar {
  padding-top: 18px;
  padding-left: 14px;
}

.update-label {
  text-align: right;
}
</style>
