<template>
  <ion-card
    v-if="message"
    :style="{
      width: fullscreenWidth + 'px',
      borderTopColor: findProjectColorById(message.projectId)
    }"
    class="unsetWidth"
  >
    <ion-card-header :class="{ iosSpacing: spacing() }">
      <div class="left-mobile">
        <svg
          v-if="messageId.message_type === 'candidate'"
          class="mobile-icon"
          width="24"
          height="24"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="closeMessage(message.candidateId)"
        >
          <g>
            <path
              d="M34.4998 31.9998L62.1998 4.1998C62.8998 3.4998 62.8998 2.3998 62.1998 1.6998C61.4998 0.999805 60.3998 0.999805 59.6998 1.6998L31.9998 29.4998L4.1998 1.7998C3.4998 1.0998 2.3998 1.0998 1.6998 1.7998C0.999805 2.4998 0.999805 3.5998 1.6998 4.2998L29.4998 31.9998L1.7998 59.7998C1.0998 60.4998 1.0998 61.5998 1.7998 62.2998C2.0998 62.5998 2.5998 62.7998 2.9998 62.7998C3.3998 62.7998 3.8998 62.5998 4.1998 62.2998L31.9998 34.4998L59.6998 62.2998C59.9998 62.5998 60.4998 62.7998 60.8998 62.7998C61.2998 62.7998 61.7998 62.5998 62.0998 62.2998C62.7998 61.5998 62.7998 60.4998 62.0998 59.7998L34.4998 31.9998Z"
              fill="black"
            />
          </g>
        </svg>
        <svg
          v-else
          class="mobile-icon"
          width="24"
          height="24"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="closeMessage(message.projectId)"
        >
          <g>
            <path
              d="M34.4998 31.9998L62.1998 4.1998C62.8998 3.4998 62.8998 2.3998 62.1998 1.6998C61.4998 0.999805 60.3998 0.999805 59.6998 1.6998L31.9998 29.4998L4.1998 1.7998C3.4998 1.0998 2.3998 1.0998 1.6998 1.7998C0.999805 2.4998 0.999805 3.5998 1.6998 4.2998L29.4998 31.9998L1.7998 59.7998C1.0998 60.4998 1.0998 61.5998 1.7998 62.2998C2.0998 62.5998 2.5998 62.7998 2.9998 62.7998C3.3998 62.7998 3.8998 62.5998 4.1998 62.2998L31.9998 34.4998L59.6998 62.2998C59.9998 62.5998 60.4998 62.7998 60.8998 62.7998C61.2998 62.7998 61.7998 62.5998 62.0998 62.2998C62.7998 61.5998 62.7998 60.4998 62.0998 59.7998L34.4998 31.9998Z"
              fill="black"
            />
          </g>
        </svg>
      </div>
      <Avatar
        v-if="message.candidateId !== undefined"
        :documents="findCandidatePhotoById(message.projectId, message.candidateId)"
        :size="35"
      />
      <AvatarProject v-else class="profile__avatar" :project-id="message.projectId" :size="35" />
      <ion-label>
        <div v-if="messageId.message_type === 'candidate'" class="wrap-text">
          <span class="pre-subject">Thema: </span>
          <br />
          {{ findCandidateNameById(message.projectId, message.candidateId) }}
          <br />
          <span class="pre-subject">An alle Job Inhaber</span>
        </div>
        <div v-else class="wrap-text">
          <span class="pre-subject">Thema: </span>
          <br />
          {{ findProjectNameById(message.projectId) }}
          <br />
          <span class="pre-subject">An alle Job Inhaber</span>
        </div>
      </ion-label>
      <div class="right">
        <ion-icon v-if="isMinimized" :icon="resize" @click="toggleFullscreen"></ion-icon>
        <ion-icon v-if="isMinimized" :icon="chevronDownOutline" @click="toggleMinimize"></ion-icon>
        <ion-icon v-else :icon="chevronUpOutline" @click="toggleMinimize"></ion-icon>
        <ion-icon
          v-if="messageId.message_type === 'candidate'"
          :icon="closeOutline"
          @click="closeMessage(message.candidateId)"
        >
        </ion-icon>
        <ion-icon v-else :icon="closeOutline" @click="closeMessage(message.projectId)"> </ion-icon>
      </div>
    </ion-card-header>
    <ion-card-content
      v-if="isMinimized"
      id="container"
      ref="scrolling"
      :class="{
        emmytalkFullscreen: emmytalkFullscreen,
        messageFullscreen: messageFullscreen
      }"
    >
      <div class="chat">
        <div
          class="chat__info"
          :class="{
            'chat__info--right': isCurrentUser(message.createdBy.cognitoUserId)
          }"
        >
          <Avatar :photo="message.createdBy.photoURL" :size="36" />
          <div class="chat__user">
            <p class="chat__username">{{ message.createdBy.firstName }} {{ message.createdBy.lastName }}</p>
            <p class="chat__date">
              {{ $d(message.createdDate, 'short') }}
            </p>
          </div>
        </div>
        <p
          class="chat__message"
          :class="{
            'chat__message--right': isCurrentUser(message.createdBy.cognitoUserId)
          }"
        >
          {{ message.text }}
        </p>
      </div>
      <ion-list>
        <div v-for="comment in message.comments" :key="comment.id">
          <Comment :comment="comment" :align-right="isCurrentUser(comment.createdBy.cognitoUserId)" />
        </div>
      </ion-list>
    </ion-card-content>
    <div v-if="isMinimized" class="footer">
      <SendArea :message-id="messageId" :project-id="message.projectId" />
    </div>
  </ion-card>
</template>

<script>
import { IonCard, IonCardHeader, IonCardContent, IonList, IonIcon, IonLabel } from '@ionic/vue'

import { chevronUpOutline, chevronDownOutline, closeOutline, resize } from 'ionicons/icons'

import { isPlatform } from '@ionic/vue'

import { computed } from 'vue'
import { useStore } from 'vuex'

import { Auth } from 'aws-amplify'

import Avatar from '@/components/ui-elements/Avatar.vue'
import AvatarProject from '@/components/ui-elements/AvatarProject.vue'
import Comment from './Comment.vue'
import SendArea from './SendArea.vue'

export default {
  name: 'MessageOverlay',
  components: {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonList,
    IonIcon,
    IonLabel,
    Avatar,
    AvatarProject,
    Comment,
    SendArea
  },
  props: { messageId: Object, isOverlay: Boolean },
  setup(props) {
    const store = useStore()

    function toggleFullscreen() {
      store.dispatch('TOGGLE_MESSAGE_FULLSCREEN', 800)
    }

    function closeMessage(id) {
      store.dispatch('CLOSE_OVERLAY_MESSAGE', id)
    }

    return {
      chevronUpOutline,
      chevronDownOutline,
      closeOutline,
      resize,
      toggleFullscreen,
      closeMessage,
      message: computed(() => store.getters.getOverlayMessage(props.messageId)),
      emmytalkFullscreen: computed(() => store.state.emmytalkFullscreen),
      messageFullscreen: computed(() => store.state.messageFullscreen),
      fullscreenWidth: computed(() => store.state.fullscreenWidth)
    }
  },
  data: function () {
    return {
      isMinimized: true,
      username: String,
      width: window.innerWidth / 2,
      height: window.innerHeight / 2
    }
  },
  watch: {
    message: {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick(function () {
          setTimeout(() => {
            this.scrollToBottom()
          }, 25)
        })
      }
    }
  },

  mounted() {
    this.$nextTick(function () {
      setTimeout(() => {
        this.scrollToBottom()
      }, 5)
    })
  },

  created() {
    window.addEventListener('resize', this.onResize)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onResize() {
      this.width = window.innerWidth
      this.height = window.innerHeight / 2
    },

    spacing() {
      if (isPlatform('iphone')) {
        return true
      }

      return false
    },

    isMobile() {
      if (isPlatform('mobile')) {
        return true
      }

      return false
    },

    scrollToBottom() {
      const container = this.$el.querySelector('#container')
      container.scrollTop = container.scrollHeight
    },

    isCurrentUser: function (createdByUser) {
      this.getUser()
      if (createdByUser === this.username) {
        return true
      }

      return false
    },

    getUser: function () {
      Auth.currentSession()
        .then(data => {
          this.username = data.accessToken.payload.username
        })
        .catch(error => {
          console.error(error)
          this.username = ''
        })
    },

    toggleMinimize() {
      this.isMinimized = !this.isMinimized
    },

    findProjectNameById: function (projectId) {
      if (projectId !== undefined) {
        return this.$store.getters.getProject(projectId).name
      }
      return ''
    },

    findProjectColorById: function (projectId) {
      if (projectId !== undefined) {
        return this.$store.getters.getProject(projectId).color
      }
      return ''
    },

    findCandidateNameById: function (projectId, candidateId) {
      if (candidateId !== undefined) {
        var candidate = this.$store.getters
          .getProject(projectId)
          .jobApplications.find(jobApplication => jobApplication.id === candidateId)

        if (candidate !== undefined) {
          return candidate.candidateName
        }
      }
      return ''
    },

    findCandidatePhotoById: function (projectId, candidateId) {
      if (candidateId !== undefined) {
        var candidate = this.$store.getters
          .getProject(projectId)
          .jobApplications.find(jobApplication => jobApplication.id === candidateId)

        if (candidate !== undefined) {
          return candidate.documents
        }
      }
      return ''
    }
  }
}
</script>

<style scoped lang="scss">
.left-mobile {
  display: none;
}

@media (max-width: 899px) {
  .left-mobile {
    display: flex;

    ion-icon {
      height: 26px;
      width: 26px;
      margin-left: 0px;
      margin-right: 14px;
    }
  }

  ion-card-content {
    height: calc(100vh / 2);
  }
}

@media (min-width: 900px) {
  ion-card-content {
    height: calc(100vh / 3);
  }

  ion-icon {
    height: 22px;
    width: 22px;
  }
}

.right {
  margin-left: auto;

  ion-icon {
    margin-left: 4px;
  }
}

@media (max-width: 899px) {
  .right {
    display: none;
  }
}

.members {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: var(--font-secondary);
}

ion-card {
  border-top-style: solid;
  border-top-width: 14px;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: 0 1px 22px rgb(33 96 147 / 20%);
  margin-top: 0px;
  margin-bottom: 0px;
  width: 400px;
}

@media (max-width: 899px) {
  ion-card {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    margin: 0;
    width: 100%;
  }
}

ion-card-content {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 16px;
  overflow-y: auto;
}

ion-card-header {
  margin-top: 18px;
  padding-top: 0px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  height: 74px;
  margin-top: 0px;
  padding-bottom: 0px;
}

@media (max-width: 899px) {
  ion-card-header {
    margin-top: 0px;
    padding-top: 0px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    align-items: center;
    height: 74px;
    margin-bottom: 18px;
    padding-bottom: 0px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #ededf0;
  }

  .mobile-icon {
    margin-right: 16px;
  }

  .unsetWidth {
    width: unset !important;
  }
}

.footer {
  margin-left: 30px;
  margin-right: 30px;
}

ion-label {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-left: 11px;

  color: var(--font-primary);
}

ion-list {
  overflow-y: auto;
}

ion-list::-webkit-scrollbar {
  width: 8px;
}
ion-list::-webkit-scrollbar-track {
  background-color: transparent;
  margin-right: 60px;
  border-radius: 10px;
}
ion-list::-webkit-scrollbar-thumb {
  background-color: #9a9eb3;
  opacity: 0.3;
  border-radius: 10px;
  height: 140px;
}

.pre-subject {
  font-size: 12px;
  font-weight: normal;
  line-height: 12px;
}

.chat {
  padding-left: 30px;
  padding-right: 30px;
}

.chat__info {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.chat__info--right {
  flex-direction: row-reverse;
  text-align: end;

  .chat__user {
    padding-right: 10px;
  }
}

.chat__user {
  padding-left: 10px;
}

.chat__username {
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;

  color: var(--font-title);
}

.chat__date {
  font-size: 12px;
  line-height: 15px;

  color: var(--font-secondary);
}
.chat__message {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  background: var(--background-secondary);
  border-radius: 0px 10px 10px 10px;
  margin-bottom: 26px;

  color: var(--font-primary);
  font-size: 14px;
  line-height: 140%;
  word-break: break-word;
}

.chat__message--right {
  background: #d5faff;
  border-radius: 10px 0px 10px 10px;
  text-align: right;
  word-break: break-word;
}

.chat__minimized {
  width: 300px;

  ion-card-header {
    height: 74px;
  }
}

.wrap-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 210px;
  overflow: hidden;
}

@media (max-width: 899px) {
  .wrap-text {
    max-width: 100%;
  }
}

.iosSpacing {
  padding-top: 10px;
}

.emmytalkFullscreen {
  min-height: calc(100vh - 247px - var(--ion-safe-area-top));
  max-height: calc(100vh - 247px - var(--ion-safe-area-top));
}

// Viewport max width 899px
@media (min-width: 899px) {
  .emmytalkFullscreen {
    min-height: 30vh;
    max-height: none;
  }
}

.messageFullscreen {
  height: calc(100vh - 525px);
}

.profile__avatar {
  padding-top: 3px;
  padding-left: 0px;
}
</style>
