<template>
  <ion-card class="project-color" :style="{ borderLeftColor: message.color }">
    <ion-card-header>
      <div class="display-flex">
        <ion-card-title class="message__title">
          {{ message.name }}
        </ion-card-title>
      </div>
      <ion-icon v-if="!isOpen" :icon="chevronDownOutline" @click="toggle()"></ion-icon>
      <ion-icon v-else :icon="chevronUpOutline" @click="toggle()"></ion-icon>
    </ion-card-header>
    <ion-card-content v-if="isOpen && getSortedCandidates != undefined">
      <ProjectCandidateMessageOverview
        v-for="candidate in getSortedCandidates"
        :key="candidate.id"
        class="candidates"
        :message="candidate"
        :is-overlay="isOverlay"
      />
    </ion-card-content>
    <ion-card-content v-if="!isOpen" class="project-avatar display-flex">
      <Avatar
        v-for="candidate in firstFiveAvatars(message.jobApplications)"
        :key="candidate.id"
        :documents="candidate.documents"
        :size="34"
        class="candidates-avatar"
      />
      <AvatarCount :count="message.jobApplications" :max-count="5" class="candidates-avatar" />
      <div class="project-buttons">
        <div class="icon-btn">
          <i-emmy-project @click.stop="openProject(message.id)" />
        </div>
        <div class="icon-btn">
          <i-emmy-message @click.stop="openMessage(message.id)" />
        </div>
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { IonCard, IonCardHeader, IonIcon, IonCardTitle, IonCardContent } from '@ionic/vue'

import { chevronDownOutline, chevronUpOutline, ellipsisHorizontalOutline, createOutline } from 'ionicons/icons'

import Avatar from '@/components/ui-elements/Avatar.vue'
import AvatarCount from '@/components/ui-elements/AvatarCount.vue'
import ProjectCandidateMessageOverview from './ProjectCandidateMessageOverview.vue'

import { useStore } from 'vuex'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'ProjectMessageOverview',
  components: {
    IonCard,
    IonCardHeader,
    IonIcon,
    IonCardTitle,
    IonCardContent,
    Avatar,
    AvatarCount,
    ProjectCandidateMessageOverview
  },
  props: { message: Object, isOverlay: Boolean },
  setup(props) {
    const store = useStore()
    const router = useRouter()

    function openMessage(id) {
      var payload = { id: id, message_type: 'project' }

      if (props.isOverlay) {
        store.dispatch('OPEN_OVERLAY_MESSAGE', payload)
      } else {
        store.dispatch('OPEN_ONE_MESSAGE', payload)
        router.push('/emmytalk')
      }
    }

    return {
      chevronDownOutline,
      chevronUpOutline,
      ellipsisHorizontalOutline,
      createOutline,
      openMessage,
      active: computed(() => store.state.openMessage),
      showInactive: computed(() => store.state.showInactive)
    }
  },
  data: function () {
    return {
      isOpen: false
    }
  },
  computed: {
    getSortedCandidates: function () {
      let unsorted = []

      if (this.showInactive === !true) {
        unsorted = this.message.jobApplications.filter(candidate => candidate.active === true)
      } else {
        unsorted = this.message.jobApplications.filter(candidate => candidate.active === false)
      }

      const sorted = unsorted.sort(function (x, y) {
        if (
          new Date(x.stepActions[x.stepActions.length - 1].timestamp) >
          new Date(y.stepActions[y.stepActions.length - 1].timestamp)
        ) {
          return -1
        }
        if (
          new Date(x.stepActions[x.stepActions.length - 1].timestamp) <
          new Date(y.stepActions[y.stepActions.length - 1].timestamp)
        ) {
          return 1
        }
      })

      return sorted
    }
  },
  methods: {
    isActive: function (id) {
      if (this.active === id) {
        return true
      }

      return false
    },

    firstFiveAvatars: function (candidates) {
      return candidates.slice(0, 5)
    },

    toggle: function () {
      this.isOpen = !this.isOpen
    },

    toggleMobileSidebar: function () {
      this.$store.dispatch('TOGGLE_SIDEBAR')

      if (this.$route.fullPath.includes('candidates')) {
        this.$store.dispatch('CHANGE_ACTIVE_SEGMENT', 'candidates')
      }

      if (this.$route.fullPath.includes('projects')) {
        this.$store.dispatch('CHANGE_ACTIVE_SEGMENT', 'project')
      }
    },

    openProject: function (id) {
      this.$router.push('/projects/' + id)

      if (window.innerWidth <= 899) {
        // Toggle sidebar on mobile
        this.toggleMobileSidebar()
      }
    }
  }
}
</script>

<style scoped lang="scss">
ion-card {
  box-shadow: none;
  margin: 7px 4px;
  padding: 7px 16px;
  border-radius: 10px;
  width: 100%;
}

ion-card-header {
  margin: 0 0 3px;
  padding: 0;
  display: flex;
  align-items: center;

  .abbreviation-icon {
    height: 30px;
    width: 30px;
    margin-right: 10px;
    background: $primary;
    padding: 15px;
    border-radius: 100px;
    color: var(--font-white);
  }

  ion-icon {
    margin-left: auto;
  }
}
.display-flex {
  display: flex;
  align-items: center;
}

ion-card-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: $primary;
  padding-right: 10px;
  margin-left: 10px;

  .message__title {
    margin-left: 14px;
  }
}

ion-card-subtitle {
  margin: 0;
  align-self: flex-start;
  font-size: 1rem;
  color: #889fb2;
}

ion-card-content {
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 0;
  font-size: 1.2rem;

  p {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  ion-card {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 0px;
    margin-right: 0px;
  }

  ion-icon {
    height: 34px;
    width: 34px;
  }
}

.active {
  --background: var(--background-step-finished);

  ion-card-title h4,
  ion-card-content {
    color: var(--font-primary);
  }
  ion-card-subtitle {
    color: var(--primary);
  }
}

ion-button {
  margin-left: auto;
  --background: transparent;
  --border-color: #25a8a9;
  --border-radius: 50px;
  --border-width: 2px;
  --border-style: solid;
  --color: #25a8a9;

  text-transform: none;
  font-size: 1.3rem;
  font-weight: 600;

  ion-icon {
    margin-right: 10px;
  }
}

.display-flex {
  display: flex;
  width: 95%;
}

.candidates-avatar {
  margin-left: -4px;
  box-shadow: 0px 1px 20px rgb(0 0 0 / 10%);
}

.candidates {
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 20px 0;
}

.footer {
  height: 32px;
  display: flex;
  justify-content: space-between;
  text-align: center;

  .open-profile {
    height: 32px;
    --border: 1px solid #50c6d2;
    --border-radius: 27px;
    background: var(--background-step-finished);
    border-radius: 27px;
    color: var(--font-white);
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    width: 200px;
    min-height: 32px !important;
    margin-right: 2px;
    --border-color: var(--font-title);
  }

  .open-chat {
    height: 32px;
    --border: 1px solid #50c6d2;
    --box-sizing: border-box;
    --border-radius: 27px;
    color: var(--font-title);
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    width: 200px;
    margin-left: 2px;
    --border-color: var(--font-title);
  }
}

.project-avatar {
  margin-top: 8px;
}

.project-buttons {
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-left: auto;
}

ion-card-content {
  ion-icon {
    float: left;
    height: 18px;
    width: 18px;
    margin-left: 6px;
    background: var(--circle-background-dark);
    padding: 8px;
    border-radius: 100px;
    color: var(--circle-color-dark);
    cursor: pointer;
    box-shadow: 0px 1px 20px rgb(0 0 0 / 10%);
  }
}

.inactiveOverlay {
  opacity: 0.75;
}

.project-color {
  border-left-style: solid;
  border-left-width: 14px;
}

.icon-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  height: 36px;
  width: 36px;
  background: var(--circle-background-dark);
  border-radius: 100px;
  color: var(--circle-color-dark);
  cursor: pointer;
  box-shadow: 0px 1px 20px rgb(0 0 0 / 10%);

  & > * {
    margin: 8px;
  }
}
</style>
