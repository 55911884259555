<script lang="ts" setup>
import { Message } from '@/models/Message'

import LayoutContainer from '@/components/LayoutContainer.vue'
import VFlex from '@/components/flex/VFlex.vue'
import MessageItem from '@/components/MessageItem.vue'

const props = defineProps<{
  modelValue: Message[]
}>()

const { modelValue } = toRefs(props)

const emit = defineEmits(['update:modelValue'])

const removeMessage = (index: number) => {
  const filteredArray = modelValue.value.filter((_, i) => i !== index)
  emit('update:modelValue', filteredArray)
}
</script>

<template>
  <VFlex class="message-container pointer-events-none">
    <MessageItem
      v-for="(message, i) in modelValue"
      :key="message.id"
      class="pointer-events-auto"
      :message="message"
      @dismiss="removeMessage(i)"
    />
  </VFlex>
</template>

<style lang="scss" scoped>
.message-container {
  max-width: 520px;
  width: 90%;
}
</style>
