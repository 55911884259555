<template>
  <ion-avatar
    :style="{
      height: size + 'px',
      width: size + 'px',
      backgroundColor: findProjectColorById
    }"
  >
    <svg class="icon" width="26" height="26" viewBox="0 0 62 46" xmlns="http://www.w3.org/2000/svg" fill="white">
      ^
      <path
        d="M55.7 8.3H43C42 3.9 37.6 0.5 32.4 0.5H29.4C24.0999 0.5 19.6999 3.9 18.7999 8.3H5.99995C2.79995 8.3 0.199951 10.9 0.199951 14.1V39.8C0.199951 43 2.79995 45.6 5.99995 45.6H55.7C58.9 45.6 61.5 43 61.5 39.8V14C61.5 10.9 58.9 8.3 55.7 8.3ZM29.4 4H32.4C35.7 4 38.5 5.8 39.4 8.3H22.4C23.2999 5.8 26.1 4 29.4 4ZM5.99995 11.8H55.7C56.9 11.8 58 12.8 58 14.1V22L32.9 26.6C32.5 26.7 31.9999 26.7 31.5999 26.6L3.79995 21.4V14C3.79995 12.8 4.79995 11.8 5.99995 11.8ZM55.7 42H5.99995C4.79995 42 3.69995 41 3.69995 39.7V25L30.9 30C31.2999 30.1 31.8 30.1 32.2 30.1C32.6 30.1 33.0999 30.1 33.5 30L58 25.5V39.7C58 41 57 42 55.7 42Z"
      />
    </svg>
  </ion-avatar>
</template>

<script>
import { IonAvatar } from '@ionic/vue'

export default {
  name: 'AvatarProject',
  components: { IonAvatar },

  props: { size: Number, projectId: String },
  computed: {
    findProjectColorById: function () {
      var project = this.$store.getters.getProject(this.projectId)
      if (project != undefined) {
        return project.color
      }

      return ''
    }
  }
}
</script>

<style scoped lang="scss">
ion-avatar {
  margin: 0;
  margin-top: 10px;
  margin-left: 6px;
  --border-radius: 100px;
}

.icon {
  margin-left: 5px;
}
</style>
