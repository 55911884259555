import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/browser'
import { HttpClient as HttpClientIntegration } from '@sentry/integrations'

import '@/styles/tailwind.css'

// import configurations

import store from './store'
import { createPinia } from 'pinia'

const pinia = createPinia()

import { IonicVue } from '@ionic/vue'

import localDE from '@/locales/de.json'
import localEN from '@/locales/en.json'

/* Core CSS required for Ionic components to work properly */
import '@/theme/globals.scss'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/core.css'
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

import { useConfigurationStore } from './pinia/configStore'

const datetimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }
  },
  de: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }
  }
}

const i18n = createI18n({
  locale: 'de',
  fallbackLocale: 'en',
  messages: {
    de: localDE,
    en: localEN
  },
  datetimeFormats
})

async function init() {
  const app = createApp(App)

  app.use(IonicVue, { mode: 'md' })
  app.use(pinia)
  app.use(store)
  app.use(i18n)

  // Initialize the configuration store before the router
  const configStore = useConfigurationStore()
  await configStore.init()

  const env = configStore.getEnv()

  app.use(router)

  Sentry.init({
    app,
    dsn: 'https://b4cfbc23313c47a69a4a3202f3202de2@o585345.ingest.sentry.io/4504553560014848',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['emmysoft.com', 'emmysoft.io']
      }),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        // maskAllText: true,
        // blockAllMedia: true
      }),
      new HttpClientIntegration({
        failedRequestStatusCodes: [[500, 599]]
      })
    ],

    // For tracing a certain amount of requests offline
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),

    // Supress or show vue errors inside the console
    logErrors: true,

    // This option is required for capturing headers and cookies.
    sendDefaultPii: true,

    enabled: true,

    // environment: 'unknown',
    // release: ''

    debug: false,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    trackComponents: true,
    // OR
    // trackComponents: ['App', 'Home', 'About', 'Contact']

    // denyUrls: [/localhost/]
    // allowUrls

    replaysOnErrorSampleRate: 1.0,

    autoSessionTracking: true,

    initialScope: {
      tags: {
        isProxy: !env.local?.isPinned ?? false,
        orgId: env.local?.id ?? undefined
      }
    },

    normalizeDepth: 10
  })

  router.isReady().then(() => {
    app.mount('#app')
  })
}

// Load the environment configuration file
// Has to be the first js file that gets loaded
const scriptElement = document.createElement('script')

if (window.environment == '%ENVIRONMENT%') {
  scriptElement.src = '/config.js'
  window.BASE_URL = ''
} else {
  scriptElement.src = '/portal/config.js'
  window.BASE_URL = '/portal'
}

document.head.appendChild(scriptElement)

// Only after the configuration file has been loaded, we can initialize the app
scriptElement.onload = () => {
  init()

  import(/* @vite-ignore */ window.VITE_APP_WEB_COMPONENT_URL)
    .then(({ register }) => {
      register()
    })
    .catch(error => {
      console.error('Failed to import the web component:', error)
    })
}
